import { useEffect, useState } from "react";
import Button from "../button";
import { useAppDispatch, useAppSelector } from "../../hooks";

import {
  selectSelectedWorkflow,
  selectWorkflowStatusLoading,
  selectWorkflowStatusMessage,
  selectWorkflowInitLoading,
  getWorkflowAsync,
  handleShowEstimateModal,
  getWorkflowsAsync,
} from "../../store/workflowSlice";
import { WorkflowStep, SignPositionType } from "../../types/workflow";
import { LoadingSpin } from "../loading";
import { selectContacts } from "../../store/contactsSlice";
import { Contact } from "../../types/contacts";
import PdfManager from "../pdf/pdfManager";
import {
  selectSelectedFile,
  selectFileById,
  selectFiles,
  getFile,
} from "../../store/filesSlice";
import { AnnotationItemType } from "../../types/pdf";
import { updateWorkflowStepApi } from "../../api/workflowApi";
import { toast } from "react-hot-toast";

const SignersManeger = () => {
  const workflow = useAppSelector(selectSelectedWorkflow);
  const file = useAppSelector(selectSelectedFile);
  const allFiles = useAppSelector(selectFiles);
  const statusLoading = useAppSelector(selectWorkflowStatusLoading);
  const statusMessage = useAppSelector(selectWorkflowStatusMessage);
  const workflowLoading = useAppSelector(selectWorkflowInitLoading);
  const myContacts = useAppSelector(selectContacts);
  const dispatch = useAppDispatch();

  const [activeStep, setActiveStep] = useState<WorkflowStep | null>(null);
  const [activeStepContact, setActiveStepContact] = useState<Contact>();
  const [coordinates, setCoordinates] = useState<Array<SignPositionType>>([]);
  const [activeStepNumber, setActiveStepNumber] = useState(0);

  useEffect(() => {
    if (workflow !== null)
      if (allFiles.length === 0) {
        dispatch(getFile({ fileId: workflow.FileId }));
      } else {
        dispatch(selectFileById({ fileId: workflow.FileId }));
      }
  }, [workflow, dispatch, allFiles.length]);

  useEffect(() => {
    if (workflow !== null) {
      const workflowSteps = workflow.WorkFlowSteps;
      if (workflowSteps.length === 0) {
        dispatch(getWorkflowAsync({ id: workflow.Id }));
      } else {
        const step = workflowSteps[activeStepNumber];
        if (step.ActionType === 0) {
          setActiveStep(step);
          setCoordinates(step.Positions || []);
        } else if (activeStepNumber + 1 < workflowSteps.length) {
          setActiveStepNumber((prev) => prev + 1);
        } else {
          dispatch(handleShowEstimateModal());
        }
      }
    }
  }, [workflow, activeStepNumber, dispatch]);

  useEffect(() => {
    if (activeStep !== null) {
      if (activeStep.ContactId) {
        const contact = myContacts.find(
          (contact) => contact.Id === activeStep.ContactId
        );
        if (contact !== undefined) setActiveStepContact(contact);
      }
      if (activeStep.Positions !== undefined) {
        setCoordinates(activeStep.Positions);
      }
    }
  }, [activeStep, myContacts]);

  const handleAddCoorToStep = (data: AnnotationItemType) => {
    setCoordinates((prev) => [
      ...prev,
      {
        Id: data.id,
        PageNum: data.page,
        XCoor: data.left,
        YCoor: data.bottom,
      },
    ]);
  };

  const handleUpdateCoorInStep = (id: number, data: AnnotationItemType) => {
    setCoordinates((prev) => {
      return prev.map((coor) => {
        if (coor.Id === id) {
          return {
            Id: coor.Id,
            PageNum: data.page,
            XCoor: data.left,
            YCoor: data.bottom,
          } as SignPositionType;
        } else {
          return { ...coor };
        }
      });
    });
  };

  const handleDeleteCoorFromStep = (id: number) => {
    setCoordinates((prev) => [...prev.filter((coor) => coor.Id !== id)]);
  };

  const handleUpdateStep = async () => {
    if (workflow && activeStep) {
      const res = await updateWorkflowStepApi({
        workflowId: workflow.Id,
        stepId: activeStep.Id,
        steps: coordinates,
      });
      if (res.status) {
        if (activeStepNumber + 1 < workflow.WorkFlowSteps.length) {
          setActiveStepNumber((prev) => prev + 1);
        } else {
          dispatch(handleShowEstimateModal());
        }
      } else {
        toast.error(res.message);
      }
    }
  };
  return (
    <div className="w-full flex flex-col mt-8 signer-manager">
      {statusLoading && (
        <div
          style={{ height: 400 }}
          className="flex items-center justify-center flex-col gap-4"
        >
          <LoadingSpin size={18} borderSize={2} />
          <span className="text-sm text-sky-500">{statusMessage}</span>
        </div>
      )}

      {!statusLoading && workflowLoading && (
        <div
          style={{ height: 400 }}
          className="flex items-center justify-center flex-col gap-4"
        >
          <LoadingSpin size={18} borderSize={2} />
          <span className="text-sm text-sky-500">Initial Data...</span>
        </div>
      )}

      {workflow !== null &&
        workflow.WorkFlowSteps.length > 0 &&
        !statusLoading &&
        activeStep !== null &&
        file &&
        file.Path && (
          <div className="flex flex-col w-full gap-6">
            <div className="flex">
              <span className="p-1 px-2 rounded-lg bg-sky-50 text-sky-500">
                {activeStepContact?.ContactDetail}
              </span>
            </div>
            <PdfManager
              path={
                file.Path ||
                "https://www.antennahouse.com/hubfs/xsl-fo-sample/pdf/basic-link-1.pdf"
              }
              width={window.innerWidth > 600 ? 450 : 300}
              positions={coordinates.map(
                (p) =>
                  ({
                    id: p.Id,
                    bottom: p.YCoor,
                    left: p.XCoor,
                    page: p.PageNum,
                    type: "place",
                  } as AnnotationItemType)
              )}
              onAddPosition={handleAddCoorToStep}
              onUpdatePosition={handleUpdateCoorInStep}
              onRemovePosition={handleDeleteCoorFromStep}
            />
          </div>
        )}

      {workflow && !statusLoading && (
        <div className="w-full mt-2 text-center flex items-center justify-between">
          <div className="flex items-center gap-2">
            {coordinates.length === 0 ? (
              ""
            ) : (
              <span className="text-sm text-slate-700">{`${
                coordinates.length
              } place${coordinates.length > 1 ? "s" : ""} must be signed ${
                activeStepContact !== undefined
                  ? `by ${activeStepContact.ContactDetail}`
                  : ""
              }`}</span>
            )}
          </div>
          <Button
            isDisabled={coordinates.length === 0}
            onClick={handleUpdateStep}
            withLoading
          >
            Next
          </Button>
        </div>
      )}
    </div>
  );
};

export default SignersManeger;
