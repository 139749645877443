import { getMousePositions } from "../../../utils/helper";
import { AnnotationItemType } from "./../../../types/pdf";

export default function AnnotationMaker({
  page,
  handleClick,
}: {
  page: number;
  handleClick: (item: AnnotationItemType) => void;
}) {
  function handleClickOnLayer(event: any) {
    const { leftPer, bottomPer } = getMousePositions(event);
    createNewAnnotation(leftPer, bottomPer);
  }

  const createNewAnnotation = (leftPer: number, bottomPer: number) => {
    const newItem: AnnotationItemType = {
      id: Math.ceil(Math.random() * 1000),
      page,
      left: leftPer,
      bottom: bottomPer,
      type: "place",
    };
    handleClick(newItem);
  };
  return (
    <div
      style={{
        position: "absolute",
        left: 0,
        top: 0,
        bottom: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "inherit",
        padding: "0",
        cursor: "copy",
      }}
      onClick={handleClickOnLayer}
    ></div>
  );
}
