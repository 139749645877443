import { useEffect, useState } from "react";
import { Document } from "react-pdf/dist/esm/entry.webpack5";
import PdfPage from "./pdfPage";
import {
  PDFDocumentProxy,
  PDFPageProxy,
} from "pdfjs-dist/types/src/display/api";
import AnnotationLoader from "./annotations/annotationLoader";
import { AnnotationItemType, PdfManagerType } from "../../types/pdf";
import AnnotationMaker from "./annotations/annotationMaker";
import { getStorage } from "../../utils/helper";

type PDFPageProxyType = { width: number | undefined } & PDFPageProxy;

const PdfManager = ({
  path,
  width,
  height,
  onLoadSuccess,
  onPageLoadSuccess,
  onLoadError,
  positions,
  onAddPosition,
  onUpdatePosition,
  onRemovePosition,
}: PdfManagerType) => {
  const [locations, setLocations] = useState<AnnotationItemType[]>([]);
  const [show, setShow] = useState(false);
  const [numPages, setNumPages] = useState<number | null>(null);

  useEffect(() => {
    setLocations(positions);
  }, [positions]);

  async function onDocumentLoadSuccess(pdf: PDFDocumentProxy) {
    setNumPages(pdf.numPages);
    setTimeout(() => setShow(true), 200);
    if (onLoadSuccess) setTimeout(() => onLoadSuccess(), 1500);
  }

  async function _onPageLoadSuccess(page: PDFPageProxyType) {
    onPageLoadSuccess && onPageLoadSuccess(page.width);
  }

  const handleUpdating = (item: AnnotationItemType) => {
    console.log("updating:", item);
  };
  const handleUpdate = (item: AnnotationItemType) => {
    console.log("go update:", item);
  };
  return (
    <div
      className={`${
        show ? "opacity-100" : "opacity-0"
      } transition-all duration-1000 h-[500px] overflow-y-auto bg-slate-100 text-center py-8 shadow-inner`}
    >
      <Document
        className="transition-all duration-1000 flex flex-col items-center gap-4"
        file={path}
        onLoadSuccess={(pdf: any) => onDocumentLoadSuccess(pdf)}
        options={{
          httpHeaders: {
            Authorization: `Bearer ${getStorage("token")}`,
          },
        }}
      >
        {Array.from(new Array(numPages), (el, index) => (
          <PdfPage
            key={`page_${index + 1}`}
            pageNumber={index + 1}
            height={height}
            width={width}
            renderMode="canvas"
            className="pdf-page shadow"
            onRenderSuccess={(page: any) => _onPageLoadSuccess(page)}
          >
            <AnnotationMaker page={index + 1} handleClick={onAddPosition} />
            <AnnotationLoader
              onDelete={(id: number) => onRemovePosition(id)}
              onUpdating={handleUpdating}
              onUpdate={handleUpdate}
              items={locations}
              page={index + 1}
            />
          </PdfPage>
        ))}
      </Document>
    </div>
  );
};

export default PdfManager;
