import { Fetch, FetchCatchResponse, FetchResponse } from "./index";

export async function getAvailableNetworkApi(): Promise<FetchResponse<any>> {
  try {
    const { data } = await Fetch.get(`/public/network`);
    return {
      data: data.Result,
      status: true,
      message: data.Message,
    };
  } catch (e) {
    return FetchCatchResponse(e);
  }
}

export async function networkHandshakeApi(
  networkId: string,
  public_key: string
): Promise<FetchResponse<any>> {
  try {
    const { data } = await Fetch.post(`/public/handshake`, {
      NetworkId: networkId,
      WalletAddress: public_key,
    });
    return {
      data: data.Message,
      status: true,
      message: "",
    };
  } catch (e) {
    return FetchCatchResponse(e);
  }
}

export async function authApi(
  networkId: string,
  public_key: string,
  signature: string
): Promise<FetchResponse<any>> {
  try {
    const { data } = await Fetch.post(`/public/auth`, {
      WalletAddress: public_key,
      Signature: signature,
      NetworkId: networkId,
    });
    return {
      data: data.Result,
      status: true,
      message: data.Message,
    };
  } catch (e) {
    return FetchCatchResponse(e);
  }
}
