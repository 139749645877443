import { ComboboxOptionType } from "../combobox";
import RecipientDragItem from "./recipientDragItem";
import { useEffect, useState } from "react";
import Button from "../button";
import { ReactComponent as ShareIcon } from "../../assets/icons/share.svg";
import { useAppDispatch, useAppSelector } from "../../hooks";
import {
  getContactsAsync,
  selectContacts,
  selectContactsLoading,
} from "../../store/contactsSlice";
import {
  initWorkflowAsync,
  selectSelectedWorkflow,
  selectWorkflowStatusLoading,
  selectWorkflowStatusMessage,
  selectWorkflowInitLoading,
  handleAddWorkflowStep,
  handleReorder,
  handleAddMe,
  updateWorkflowAsync,
  getWorkflowsAsync,
} from "../../store/workflowSlice";
import { DragArea, DragItem } from "../drag";
import { WorkflowStep, WorkflowManegerType } from "../../types/workflow";
import { LoadingSpin } from "../loading";
import { checkContactsInWorkflowSteps } from "../../utils/helper";

const WorkflowManeger = ({ file }: WorkflowManegerType) => {
  const workflow = useAppSelector(selectSelectedWorkflow);
  const statusLoading = useAppSelector(selectWorkflowStatusLoading);
  const statusMessage = useAppSelector(selectWorkflowStatusMessage);
  const workflowLoading = useAppSelector(selectWorkflowInitLoading);
  const [canGoNext, setCanGoText] = useState(false);
  if (workflow !== null) {
    // console.log("workflow:", workflow, workflow.workflowSteps);
  }

  const myContacts = useAppSelector(selectContacts);
  const contactsLoading = useAppSelector(selectContactsLoading);
  const dispatch = useAppDispatch();
  const people: Array<ComboboxOptionType> = [
    ...myContacts.map(
      (contact) =>
        ({
          value: contact.Id,
          label: contact.ContactDetail,
        } as unknown as ComboboxOptionType)
    ),
  ];

  const me = myContacts.find((c) => c.IsCurrentUser);

  useEffect(() => {
    dispatch(initWorkflowAsync());
  }, [dispatch]);

  useEffect(() => {
    if (myContacts.length === 0) dispatch(getContactsAsync());
  }, [dispatch, myContacts.length]);

  useEffect(() => {
    if (workflow !== null) {
      setCanGoText(checkContactsInWorkflowSteps(workflow));
    } else {
      setCanGoText(false);
    }
  }, [workflow]);

  return (
    <div className="w-full flex flex-col mt-8">
      {(statusLoading || workflowLoading) && (
        <div
          style={{ height: 300 }}
          className="flex items-center justify-center flex-col gap-4"
        >
          <LoadingSpin size={18} borderSize={2} />
          {statusLoading ? (
            <span className="text-sm text-sky-500">{statusMessage}</span>
          ) : (
            <span className="text-sm text-sky-500">Initial Data...</span>
          )}
        </div>
      )}

      {workflow !== null &&
        workflow.WorkFlowSteps.length > 0 &&
        !statusLoading &&
        !workflowLoading && (
          <DragArea<WorkflowStep>
            onReorderEnd={(items) => dispatch(handleReorder(items))}
            items={workflow.WorkFlowSteps}
          >
            {workflow.WorkFlowSteps.map((item, index) => (
              <DragItem key={index} id={item.Id} index={index}>
                <RecipientDragItem
                  order={index}
                  contacts={people}
                  initLoading={contactsLoading}
                  recipient={item}
                />
              </DragItem>
            ))}
          </DragArea>
        )}

      {workflow !== null &&
        workflow.WorkFlowSteps.length === 0 &&
        !statusLoading && (
          <div className="w-full bg-gray-50 text-gray-700 rounded-xl flex flex-col items-center justify-center py-14 gap-3">
            <span>Add a recipient in your workflow</span>
          </div>
        )}

      {workflow && !statusLoading && (
        <div className="w-full mt-2 text-center flex items-center justify-between">
          <div className="flex items-center gap-2">
            <Button
              withLoading
              startIcon={<ShareIcon className="w-4 h-4" />}
              light={true}
              onClick={() => {
                dispatch(handleAddWorkflowStep());
              }}
            >
              Add Recipient
            </Button>
            {me && (
              <Button
                light={true}
                onClick={() => dispatch(handleAddMe({ me }))}
              >
                Add Me
              </Button>
            )}
          </div>
          <Button
            isDisabled={!canGoNext}
            onClick={
              () => dispatch(updateWorkflowAsync())
              // .then(() =>
              //   dispatch(getWorkflowsAsync())
              // )
            }
          >
            Next
          </Button>
        </div>
      )}
    </div>
  );
};
export default WorkflowManeger;
