import Loading from "../loading";
import Modal, { ModalContainerProps } from "./modal";
import {
  selectContactsLoading,
  getContactsAsync,
  selectContacts,
  createContactAsync,
  deleteContactAsync,
} from "../../store/contactsSlice";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import Button from "../button";
import { ReactComponent as PlusIcon } from "../../assets/icons/plus.svg";
import { Contact } from "../../types/contacts";
import useShow from "../../hooks/useShow";
import Input from "../input";
import { ReactComponent as DeleteIcon } from "../../assets/icons/delete.svg";
export type FileViewerProps = ModalContainerProps;

function ContactsModal({ show, onClose }: FileViewerProps) {
  const contacts = useAppSelector(selectContacts);
  const contactsLoading = useAppSelector(selectContactsLoading);
  const dispatch = useAppDispatch();

  const [
    showNewContactsModal,
    { show: handleShowNewContacts, hide: handleHideNewContacts },
  ] = useShow();

  useEffect(() => {
    if (show) dispatch(getContactsAsync());
  }, [dispatch, show]);

  return (
    <Modal
      title={"My contacts"}
      modalClassName="w-full lg:w-[600px] bg-slate-100"
      containerStyle={{ alignItems: "flex-start", overflowY: "auto" }}
      show={show}
      onClose={onClose}
    >
      <NewContactsModal
        show={showNewContactsModal}
        onClose={() => handleHideNewContacts()}
      />
      {contactsLoading && (
        <div style={{ height: 200 }}>
          <Loading size={18} borderSize={2} />
        </div>
      )}
      {!contactsLoading && (
        <>
          <div className="w-full mt-2 text-center flex flex-col gap-3 mb-3">
            {contacts.map((contact, index) => (
              <ContactRow key={index} contact={contact} />
            ))}
          </div>
          {!contacts.length && (
            <div className="w-full bg-sky-50 text-sky-500 rounded-xl flex flex-col items-center justify-center py-5 gap-3">
              <span>You dont have any contact</span>
              <Button light={true} onClick={handleShowNewContacts}>
                Create one
              </Button>
            </div>
          )}
          <div className="w-full mt-2 text-center flex items-center justify-between">
            {contacts.length > 0 && (
              <div className="flex items-center">
                <Button
                  startIcon={<PlusIcon className="w-4 h-4" />}
                  light={true}
                  onClick={handleShowNewContacts}
                >
                  add contact
                </Button>
              </div>
            )}
          </div>
        </>
      )}
    </Modal>
  );
}

const ContactRow = ({ contact }: { contact: Contact }) => {
  const dispatch = useAppDispatch();
  const handleDeleteContact = (id: number) =>
    dispatch(deleteContactAsync({ id }));
  return (
    <div className="w-full p-2 px-3 border-2 rounded-lg flex justify-between">
      {contact.ContactDetail}
      <DeleteIcon
        className="w-6 h-6 text-slate-600 hover:text-slate-800 cursor-pointer hover:bg-sky-100 p-1 rounded-lg"
        onClick={() => handleDeleteContact(contact.Id)}
      />
    </div>
  );
};

function NewContactsModal({ show, onClose }: FileViewerProps) {
  const contactsLoading = useAppSelector(selectContactsLoading);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const dispatch = useAppDispatch();

  const handleCreateNewContact = () => {
    dispatch(createContactAsync({ Name: name, Email: email })).then(() => {
      setName("");
      setEmail("");
      onClose();
    });
  };

  const handleChangeFullname = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setName(e.target.value);
    },
    []
  );

  const handleChangeEmail = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  }, []);

  return (
    <Modal
      title={"Create contact"}
      modalClassName="w-full lg:w-[600px] bg-slate-100"
      containerStyle={{ alignItems: "flex-start", overflowY: "auto" }}
      show={show}
      onClose={onClose}
    >
      {contactsLoading && (
        <div style={{ height: 200 }}>
          <Loading size={18} borderSize={2} />
        </div>
      )}
      {!contactsLoading && (
        <>
          <div className="w-full mt-2 text-center flex flex-col items-center justify-between gap-2">
            <Input
              type="text"
              value={name}
              placeholder="Fullname"
              fullWidth={true}
              onChange={handleChangeFullname}
            />
            <Input
              type="text"
              value={email}
              placeholder="Email"
              fullWidth={true}
              onChange={handleChangeEmail}
            />
            <Button
              isDisabled={name.length === 0 || email.length === 0}
              onClick={handleCreateNewContact}
            >
              save contact
            </Button>
          </div>
        </>
      )}
    </Modal>
  );
}
export default ContactsModal;
