import { AnnotationItemType } from "../../../types/pdf";
import { PDFDocument } from "pdf-lib";
import qrCode from "qrcode";
import { createCanvas, loadImage } from "canvas";
import { getStorage } from "../../../utils/helper";

export const createPDF = async (
  file: string,
  sign: string,
  positions: Array<AnnotationItemType> | []
): Promise<{ blob: Blob; filename: string }> => {
  return fetch(file, {
    headers: {
      Authorization: `Bearer ${getStorage("token")}`,
    },
  })
    .then((response) => response.arrayBuffer())
    .then(async (arrayBuffer) => {
      const pdfDoc = await PDFDocument.load(arrayBuffer);
      positions.map(async ({ page, left, bottom, type, valueType, value }) => {
        if (value !== undefined) {
          const pageContent = pdfDoc.getPages()[page - 1];
          const { width, height } = pageContent.getSize();
          if (valueType === "text" && value !== undefined) {
            const fontSize = 7;
            pageContent.drawText(value, {
              x: (left * width) / 100,
              y: (bottom * height) / 100,
              size: fontSize,
            });
          }
          if (valueType === "image") {
            const base64Data = sign.slice(sign.indexOf(",") + 1);
            const binaryString = window.atob(base64Data);
            const bytes = new Uint8Array(binaryString.length);
            for (let i = 0; i < binaryString.length; i++) {
              bytes[i] = binaryString.charCodeAt(i);
            }
            const image = await pdfDoc.embedPng(bytes);
            pageContent.drawImage(image, {
              x: (left * width) / 100,
              y: (bottom * height) / 100,
              width: 80,
              height: 80,
            });
          }
        }
      });
      const pdfBytesWithAnnotations = await pdfDoc.save();
      const filename = "my_pdf_file.pdf";
      console.log(pdfBytesWithAnnotations);

      const blob = new Blob([pdfBytesWithAnnotations], {
        type: "application/pdf",
      });
      return { blob, filename };
    });
};

export const getPDF = async (file: string) => {
  try {
    const responce = await fetch(file, {
      headers: {
        Authorization: `Bearer ${getStorage("token")}`,
      },
    });
    if (responce.ok) {
      const arrayBuffer = await responce.arrayBuffer();
      const filename = "my_pdf_file.pdf";
      const blob = new Blob([arrayBuffer], {
        type: "application/pdf",
      });
      return { status: true, blob, filename };
    } else {
      const res = await responce.json();
      return { status: false, message: res.message };
    }
  } catch (e) {
    console.log("catch:", e);
  }
};

export const qrGenerator = async (text: string) => {
  const size = 100;
  const margin = 3;
  const qrCodeDataUrl = await qrCode.toDataURL(text, {
    width: size,
    margin,
  });
  const canvas = createCanvas(size, size);
  const context = canvas.getContext("2d");
  context.drawImage(await loadImage(qrCodeDataUrl), 0, 0, size, size);
  return canvas.toDataURL();
};
