import { ReactComponent as SignIcon } from "../../../assets/icons/edit.svg";
const SignShowAnnotation = ({
  id,
  left,
  bottom,
  value,
  valueType,
}: {
  id: number;
  left: number;
  bottom: number;
  value?: string;
  valueType?: "text" | "image";
}) => {
  // const [isHover, setIsHover] = useState(false);

  return (
    <div
      className={`sign-place-annotation relative ${
        value !== undefined
          ? ""
          : "bg-sky-500 text-white p-2 rounded-md shadow flex items-center justify-center "
      }`}
      style={{
        position: "absolute",
        left: left + "%",
        bottom: bottom + "%",
      }}
      // onMouseEnter={() => setIsHover(true)}
      // onMouseLeave={() => setIsHover(false)}
    >
      {value === undefined && (
        <div className="flex w-full flex-col items-center">
          <SignIcon className="w-3 h-3 " />
          <span className="text-[0.42rem] hidden lg:block mt-1 whitespace-nowrap">
            Your Sign
          </span>
        </div>
      )}
      {value && valueType === "text" && (
        <span className="text-slate-900 text-[6px]">{value}</span>
      )}
      {value && valueType === "image" && (
        <img
          style={{ width: window.innerWidth > 600 ? 50 : 25 }}
          className="w-14"
          alt=""
          src={value}
        />
      )}
    </div>
  );
};
export default SignShowAnnotation;
