import { useCallback, useEffect, useState } from "react";
import Modal, { ModalContainerProps } from "./modal";
import styles from "./../../styles/PayModal.module.scss";
import { ReactComponent as Logo } from "./../../assets/icons/logo.svg";
import { ReactComponent as QuestionIcon } from "./../../assets/icons/question.svg";
import classNames from "classnames";
import Button from "../button";
import Loading from "../loading";
import { sleep } from "../../utils/helper";
import toast from "react-hot-toast";
import { ReactComponent as TicIcon } from "./../../assets/icons/tic.svg";
import { ReactComponent as ErrorIcon } from "./../../assets/icons/close.svg";
import { ReactComponent as DownloadIcon } from "./../../assets/icons/download.svg";
import { ReactComponent as CopyIcon } from "./../../assets/icons/copy.svg";
import { Transaction, VersionedTransaction } from "@solana/web3.js";
import { useConnection } from "@solana/wallet-adapter-react";
import Switch from "../switch";
import { Link } from "react-router-dom";
import useShow from "../../hooks/useShow";
import useWindowOnClick from "../../hooks/useWindowOnClick";
import { useAppSelector } from "../../hooks";
import { selectCredit } from "../../store/profileSlice";
import { FetchResponse } from "../../api";
import { StatusApiResponce, ConfirmApiResponce } from "../../types/payment";
import { PaymentStatusType } from "../../types/activity";

export type PayModalProps = {
  amount?: number | (() => Promise<number>);
  withCredit?: boolean;
  onPay?: () => Promise<
    | {
        tracking_code: string;
        tx: Transaction;
      }
    | undefined
  >;
  onPayByCredit?: () => Promise<void>;
  onAfterPay?: (byCredit?: boolean, tcode?: string) => void | Promise<void>;
  type: "topup" | "storage" | "nft";
  confirmTx?: (tCode: string) => Promise<FetchResponse<ConfirmApiResponce>>;
  statusTx?: (signature: string) => Promise<FetchResponse<StatusApiResponce>>;
  sendTransaction?: (tx: Transaction) => Promise<string>;
} & ModalContainerProps;

function PayModal({
  show,
  onClose,
  amount,
  onPay,
  confirmTx,
  statusTx,
  withCredit,
  onAfterPay,
  type,
  onPayByCredit,
  sendTransaction,
}: PayModalProps) {
  const credit = useAppSelector(selectCredit) || 0;

  const [price, setPrice] = useState<number>();
  const [priceLoading, setPriceLoading] = useState(true);
  const [showMainModal, setShowMainModal] = useState(show);
  const [showStatusModal, setShowStatusModal] = useState(false);
  const [trackingCode, setTrackingCode] = useState("");
  const [payFromCredits, setPayFromCredits] = useState(true);
  const [status, setStatus] = useState<"loading" | "ok" | "error">("loading");
  const { connection } = useConnection();
  const [showQ, { toggle: toggleQ, hide: hideQ }] = useShow();

  useEffect(() => {
    if (credit < Number(price)) setPayFromCredits(false);
    else setPayFromCredits(true);
  }, [credit, price]);

  useEffect(() => {
    setShowMainModal(show);
    setShowStatusModal(false);
    setPriceLoading(true);
    setPayFromCredits(true);
  }, [show]);

  useEffect(() => {
    if (show && amount) {
      if (typeof amount === "number") {
        setPrice(amount);
        setPriceLoading(false);
      } else {
        amount()
          .then((res) => {
            setPrice(res);
            setPriceLoading(false);
          })
          .catch((e) => {
            if (e?.data && typeof e.data === "string") {
              toast.error(e.data);
            } else {
              toast.error("Something went wrong!");
            }
            onClose();
          });
      }
    }
  }, [amount, onClose, show]);

  useWindowOnClick(() => {
    hideQ();
  }, []);

  const handleDonloadTrackingCode = useCallback(async () => {
    const element = document.createElement("a");
    let text = `Your payment is being proceed, here is your tracking code.\n\n${trackingCode}\n\nYour storage will be increased as soon as transaction is confirmed`;
    if (type === "nft")
      text = `Your payment is being proceed, here is your tracking code.\n\n${trackingCode}\n\nYour NFT will be minted as soon as transaction is confirmed`;
    if (type === "topup")
      text = `Your payment is being proceed, here is your tracking code.\n\n${trackingCode}\n\nYour credit will be increased as soon as transaction is confirmed`;
    element.setAttribute(
      "href",
      "data:text/plain;charset=utf-8," + encodeURIComponent(text)
    );
    element.setAttribute("download", `${trackingCode}.txt`);

    element.style.display = "none";
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  }, [trackingCode, type]);

  const handlePay = useCallback(async () => {
    if (payFromCredits && type !== "topup") {
      // if (!onPayByCredit) return;
      // try {
      //   await onPayByCredit();
      //   if (onAfterPay) await onAfterPay(payFromCredits);
      //   onClose();
      // } catch (e: any) {
      //   if (e?.data && typeof e.data === "string") {
      //     toast.error(e.data);
      //   } else {
      //     toast.error("Something went wrong!");
      //   }
      // }
    } else {
      if (!onPay || !confirmTx || !statusTx || !sendTransaction) return;
      try {
        const result = await onPay();
        if (!result) return;
        const { tracking_code, tx } = result;
        setTrackingCode(tracking_code);
        setStatus("loading");
        setShowMainModal(false);
        setShowStatusModal(true);

        const signature = await sendTransaction(tx);
        await confirmTx(signature);

        let finalized = false;
        let paymentStatus: PaymentStatusType = "Pending";
        while (!finalized) {
          const statusResponse = await statusTx(signature);
          if (statusResponse.data === null) {
            finalized = true;
            paymentStatus = "Failed";
          } else {
            finalized = statusResponse.data.IsFinalized;
            paymentStatus = statusResponse.data.Status;
          }
          if (!finalized) await sleep(5000);
        }
        if (onAfterPay) await onAfterPay(payFromCredits, tracking_code);
        if (paymentStatus === "Success") {
          setStatus("ok");
          setTimeout(() => {
            onClose();
          }, 1500);
        } else if (paymentStatus === "Failed") {
          setStatus("error");
        }
      } catch (e: any) {
        console.log("handlePay errors:", { e });
        if (e?.data && typeof e.data === "string") {
          toast.error(e.data);
          setStatus("error");
        } else if (e?.message === "insufficient funds") {
          toast.error("You don't have sufficient DJIB!");
        } else if (e?.message === "sender not found") {
          toast.error(
            "You need at least 0.0001 SOL for creating transactions on the Solana network!"
          );
        } else if (e?.name === "TokenAccountNotFoundError") {
          toast.error("You don't have sufficient DJIB!");
        } else if (e?.error?.code === 4001 || e?.error?.code === -32603) {
        } else {
          toast.error("Something went wrong!");
          setStatus("error");
        }
      }
    }
  }, [
    confirmTx,
    onAfterPay,
    onClose,
    onPay,
    payFromCredits,
    sendTransaction,
    statusTx,
    type,
  ]);

  const handleCopyTcode = useCallback(() => {
    toast.promise(navigator.clipboard.writeText(trackingCode), {
      loading: "Copy...",
      success: <p>Copied!</p>,
      error: <p>Failed.</p>,
    });
  }, [trackingCode]);

  return (
    <>
      <Modal
        title={""}
        bodyClassName={styles.body}
        modalClassName="w-[300px] lg:w-[350px] bg-slate-100"
        show={showMainModal}
        onClose={onClose}
        onClick={hideQ}
      >
        <div className={classNames("flex justify-center", styles.logo)}>
          <Logo />
        </div>
        <div className={styles.desc}>
          {/* <p>Your Credit shows</p>
          <p>Top up your credit to Avoid extra network fees</p> */}
        </div>
        <div className={styles.amount}>
          {priceLoading && (
            <p
              className="flex justify-center items-center"
              style={{ height: 36 }}
            >
              <Loading borderSize={2} size={20} />
            </p>
          )}
          {!priceLoading && <p>{price}</p>}

          <div>
            <Logo />
            <p>DJIB</p>
          </div>
        </div>

        {withCredit && (
          <div className="my-8 flex">
            <div className={styles.switch}>
              <Switch
                active={payFromCredits}
                onClick={() =>
                  setPayFromCredits((prev) => {
                    if (credit < Number(amount)) return false;
                    return !prev;
                  })
                }
                label=""
              />
              <div>
                <p>Pay from credits</p>
                <p className="text-slate-400" style={{ fontSize: "0.75rem" }}>
                  Your balance: {credit} DJIB
                </p>
              </div>
            </div>
            <div className={classNames("ml-4", styles.question)}>
              <QuestionIcon
                width={24}
                height={24}
                onClick={(e: any) => {
                  e.stopPropagation();
                  toggleQ();
                }}
              />
              {showQ && (
                <div className={styles.qinfo}>
                  By adding to your credit you can benefit from less network
                  fees on blockchains.
                  <br />
                  You can top up here{" "}
                  <Link to={"/settings"} target={"_blank"} rel="noreferrer">
                    (link)
                  </Link>
                  <br />
                  to read more{" "}
                  <a
                    href="https://docs.djib.io/"
                    target={"_blank"}
                    rel="noreferrer"
                  >
                    (docs)
                  </a>
                </div>
              )}
            </div>
          </div>
        )}

        <Button
          className={styles.payBtn}
          onClick={handlePay}
          withLoading={true}
          fullWidth={true}
          isDisabled={priceLoading}
        >
          Pay
        </Button>
      </Modal>

      <Modal
        title={""}
        bodyClassName={styles.body}
        show={showStatusModal}
        onClose={onClose}
      >
        <div
          className={classNames(styles.status, {
            [styles.success]: status === "ok",
            [styles.error]: status === "error",
          })}
        >
          {status === "loading" && (
            <Loading
              size={60}
              borderColor={"rgba(var(--color-primary-rgb), 0.12)"}
              color={"#0074e5"}
            />
          )}
          {status === "ok" && (
            <TicIcon width={60} height={60} color={"#28C76F"} />
          )}
          {status === "error" && (
            <ErrorIcon width={60} height={60} color={"#D52B1E"} />
          )}
        </div>
        <div className={styles.trackingCode}>
          <p>Your payment is being proceed,</p>
          <p>here is your tracking code.</p>
          <span
            className={classNames(styles.tcode, "font-mono")}
            onClick={handleCopyTcode}
          >
            {trackingCode}
            <CopyIcon />
          </span>
          {type === "topup" && <p>Your credit will be increased</p>}
          {type === "storage" && <p>Your storage will be increased</p>}
          {type === "nft" && <p>Your file will be Saved as NFT</p>}
          <p>as soon as transaction is completed.</p>
        </div>
        <div className={styles.footer}>
          <div className="flex flex-col gap-2 w-full text-center px-2">
            <Button
              fullWidth={true}
              extraLight={true}
              onClick={handleDonloadTrackingCode}
              style={{ marginTop: "1.5rem" }}
              startIcon={<DownloadIcon />}
              withLoading={false}
            >
              Download
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default PayModal;
