import React, { useEffect } from "react";
import { ReactComponent as DjibLogo } from "./../assets/icons/logo.svg";
import Button from "./button";
import TopUpModal from "./modals/TopUpModal";
import useShow from "../hooks/useShow";
import Loading from "./loading";
import { useAppDispatch, useAppSelector } from "../hooks";
import {
  selectLoadingBalance,
  selectCredit,
  fetchBalance,
} from "../store/profileSlice";
import { selectSelectedNetwork } from "../store/networksSlice";
function Credit() {
  const dispatch = useAppDispatch();
  const [showTopUpModal, { show, hide }] = useShow();
  const loading = useAppSelector(selectLoadingBalance);
  const balance = useAppSelector(selectCredit);
  const selectedNetwork = useAppSelector(selectSelectedNetwork);

  useEffect(() => {
    dispatch(fetchBalance());
  }, [dispatch]);

  return (
    <div className="flex py-8 pr-4 gap-2 justify-center">
      <TopUpModal show={showTopUpModal} onClose={hide} />
      {loading && (
        <div
          className="flex justify-center items-center w-full"
          style={{ height: 200 }}
        >
          <Loading />
        </div>
      )}
      {!loading && (
        <>
          <div className="flex flex-col gap-3 items-center md:items-start">
            <p className="text-lg text-slate-600">Your balance</p>
            <div className="flex gap-1 items-center">
              <p className="text-5xl font-medium text-slate-600">
                {balance || 0}
              </p>
              <DjibLogo className="w-14 h-14" />
            </div>
            <div className="flex flex-col gap-1 items-center md:items-start">
              <Button
                isDisabled={
                  !selectedNetwork || selectedNetwork.NetworkName !== "solana"
                }
                onClick={show}
                className="mt-auto mb-1 w-full"
              >
                TOP UP
              </Button>
              <p className="text-xs text-slate-500 md:pr-4">
                {selectedNetwork && selectedNetwork.NetworkName === "solana" ? (
                  <>
                    {`Don't have enough DJIB To buy Storage? `}
                    <a
                      className="text-sky-500"
                      href="https://buy.djib.io/"
                      target={"_blank"}
                      rel="noreferrer"
                    >
                      Buy here
                    </a>
                  </>
                ) : (
                  <>{`Top up is not available at the time!`}</>
                )}
              </p>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default Credit;
