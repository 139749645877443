import { useEffect, useRef, useState } from "react";
import { ReactComponent as CheckIcon } from "./../../../assets/icons/check.svg";
import { ReactComponent as SaveIcon } from "./../../../assets/icons/save.svg";
import { ReactComponent as RefreshIcon } from "./../../../assets/icons/refresh.svg";
import Button from "../../button";

interface PaintableAreaProps {
  onChange?: (base65: string) => void;
  onSign: () => void;
}
interface CanvasRenderingContext2DWithPixelRatio
  extends CanvasRenderingContext2D {
  webkitBackingStorePixelRatio?: number;
}

const PaintSignImage: React.FC<PaintableAreaProps> = ({ onSign, onChange }) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const isPaintingRef = useRef<boolean>(false);
  const [hasDrawn, setHasDrawn] = useState<boolean>(false);
  const colors = ["#000", "#1d4ed8", "#b91c1c"];
  const [penColor, setPenColor] = useState<string>(colors[0]);

  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas?.getContext(
      "2d"
    ) as CanvasRenderingContext2DWithPixelRatio;

    if (!canvas || !context) {
      return;
    }
    const container = canvas.parentElement;
    if (!container) {
      return;
    }
    const containerWidth = container.clientWidth;
    // const containerHeight = container.clientHeight;
    canvas.height = canvas.width = containerWidth;
    context.lineWidth = 2;
    context.lineCap = "round";
    context.strokeStyle = penColor;
  }, [penColor]);

  useEffect(() => {
    setHasDrawn(false);
  }, [penColor]);

  function startPainting(
    event:
      | React.MouseEvent<HTMLCanvasElement>
      | React.TouchEvent<HTMLCanvasElement>
  ) {
    const canvas = canvasRef.current;
    const context = canvas?.getContext("2d");

    if (!canvas || !context) {
      return;
    }
    lockViewport();
    isPaintingRef.current = true;

    let x = 0;
    let y = 0;

    if (event.nativeEvent instanceof MouseEvent) {
      x = event.nativeEvent.offsetX;
      y = event.nativeEvent.offsetY;
    } else if (event.nativeEvent instanceof TouchEvent) {
      x = event.nativeEvent.touches[0].pageX - canvas.offsetLeft;
      y = event.nativeEvent.touches[0].pageY - canvas.offsetTop;
    }

    context.beginPath();
    context.moveTo(x, y);
  }

  function stopPainting() {
    isPaintingRef.current = false;
    unlockViewport();
    setHasDrawn(true);
    const dataURL = createImage();
    if (dataURL && onChange) onChange(dataURL);
  }

  function draw(
    event:
      | React.MouseEvent<HTMLCanvasElement>
      | React.TouchEvent<HTMLCanvasElement>
  ) {
    const canvas = canvasRef.current;
    const context = canvas?.getContext("2d");

    if (!canvas || !context) {
      return;
    }

    if (!isPaintingRef.current) {
      return;
    }

    let x = 0;
    let y = 0;

    if (event.nativeEvent instanceof MouseEvent) {
      x = event.nativeEvent.offsetX;
      y = event.nativeEvent.offsetY;
    } else if (event.nativeEvent instanceof TouchEvent) {
      x = event.nativeEvent.touches[0].pageX - canvas.offsetLeft;
      y = event.nativeEvent.touches[0].pageY - canvas.offsetTop;
    }

    context.lineTo(x, y);
    context.stroke();
  }

  function resetCanvas() {
    const canvas = canvasRef.current;
    const context = canvas?.getContext("2d");

    if (!canvas || !context) {
      return;
    }

    context.clearRect(0, 0, canvas.width, canvas.height);
    setHasDrawn(false);
  }

  function saveImage() {
    const dataURL = createImage();
    if (dataURL) {
      const link = document.createElement("a");
      link.download = "djib-my-sign.png";
      link.href = dataURL;
      link.click();
    }
  }

  function createImage(): string | undefined {
    const canvas = canvasRef.current;
    if (!canvas) {
      return;
    }
    const dataURL = canvas.toDataURL("image/png");
    return dataURL;
  }

  function lockViewport() {
    const body = document.querySelector("body");
    if (body) body.style.overflow = "hidden";
  }

  function unlockViewport() {
    setTimeout(() => {
      const body = document.querySelector("body");
      if (body) body.style.overflow = "";
    }, 200);
  }

  return (
    <div className="mt-8">
      <div className="flex w-full justify-between items-center">
        <span className="text-sm text-slate-500">Draw your sign</span>
        <SaveIcon
          title="Download your sign"
          className={`w-7 h-7 p-1 ${
            hasDrawn
              ? "text-slate-400 hover:text-sky-500 cursor-pointer"
              : "text-slate-300"
          }`}
          onClick={() => (hasDrawn ? saveImage() : null)}
        />
      </div>
      <div className="w-full h-full bg-sky-50 text-sky-500 rounded-lg cursor-pointer flex justify-center items-center shadow">
        <canvas
          ref={canvasRef}
          style={{ width: "100%", height: "100%" }}
          onMouseDown={startPainting}
          onMouseUp={stopPainting}
          onMouseMove={draw}
          onTouchStart={startPainting}
          onTouchEnd={stopPainting}
          onTouchMove={draw}
        />
      </div>
      <div className="w-full h-8 flex justify-between mt-2 px-2 items-center">
        <div className="flex gap-2">
          {colors.map((color) => (
            <div
              key={color}
              onClick={() => setPenColor(color)}
              className="w-5 h-5 rounded-full cursor-pointer opacity-75 flex justify-center items-center"
              style={{ backgroundColor: color }}
            >
              {penColor === color && (
                <CheckIcon className="w-3 h-3 text-white" />
              )}
            </div>
          ))}
        </div>
        <span
          onClick={() => (hasDrawn ? resetCanvas() : null)}
          className={`text-base flex items-center gap-[0.1rem]  ${
            hasDrawn
              ? "text-sky-500 hover:text-sky-600 cursor-pointer"
              : "text-sky-200"
          }`}
        >
          <RefreshIcon className="w-4" />
          reset
        </span>
      </div>
      <Button
        isDisabled={!hasDrawn}
        className="mt-5"
        fullWidth
        withLoading
        onClick={onSign}
      >
        Sign Document
      </Button>
    </div>
  );
};

export default PaintSignImage;
