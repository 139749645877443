import { useRef } from "react";
import styles from "./../styles/File.module.scss";
import { ReactComponent as PlusIcon } from "./../assets/icons/plus.svg";
import { ReactComponent as OpenIcon } from "./../assets/icons/pdf-open.svg";
import { ReactComponent as DeleteIcon } from "./../assets/icons/delete.svg";
import IconButton from "./iconButton";
import classNames from "classnames";
import FileIcon, { FileTypeType } from "./fileIcon";
import { humanizeSize } from "../utils/file";
import { FileType } from "../types/file";
import { useAppDispatch, useAppSelector } from "../hooks";
import { createWorkflowAsync } from "../store/workflowSlice";
import { selectFile } from "../store/filesSlice";
import { selectCredit } from "../store/profileSlice";
import Highlighter from "react-highlight-words";
import { useSearch } from "../providers/SearchProvider";

export type FileProps = {
  name: string;
  extension: number;
  size: string;
  file: FileType;
  createdAt?: string;
  onOpenFile?: (file: FileType) => void;
  onDeleteFile?: (file: FileType) => void;
  onOpenError?: () => void;
};

function File({
  name,
  extension,
  size,
  file,
  createdAt,
  onOpenFile,
  onOpenError,
  onDeleteFile,
}: FileProps) {
  const ref = useRef(null);
  const dispatch = useAppDispatch();
  const balance = useAppSelector(selectCredit);
  const { value: searchValue } = useSearch();
  const handleCreateWorkflow = () => {
    if (balance && balance > 0) {
      dispatch(createWorkflowAsync({ fileId: file.Id })).then(() => {
        dispatch(selectFile({ file }));
      });
    } else {
      if (typeof onOpenError === "function") onOpenError();
    }
  };
  return (
    <div
      className={classNames(styles.file)}
      ref={ref}
      // onClick={handleClick}
    >
      <div className={classNames(styles.name, "col-name")}>
        <FileIcon type={file.FileType as FileTypeType} />
        <p>
          <Highlighter
            highlightClassName="YourHighlightClass"
            searchWords={[searchValue]}
            autoEscape={true}
            textToHighlight={`${name}
            ${extension ? `.${extension}` : ""}`}
          />
        </p>
      </div>

      <div className={classNames(styles.size, "col-size")}>
        {humanizeSize(Number(size), { fractionDigits: 2 }).join("B")}
      </div>

      <div className={classNames(styles.action, "col-action")}>
        <div className="flex gap-2">
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              onDeleteFile && onDeleteFile(file);
            }}
          >
            <DeleteIcon className="w-6" />
          </IconButton>

          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              onOpenFile && onOpenFile(file);
            }}
          >
            <OpenIcon />
          </IconButton>

          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              // onOpenWorkflow && onOpenWorkflow(file);
              handleCreateWorkflow();
            }}
          >
            <PlusIcon />
          </IconButton>
        </div>
      </div>
    </div>
  );
}

export default File;
