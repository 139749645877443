import { useCallback, useState } from "react";
import Modal, { ModalContainerProps } from "./modal";
import styles from "./../../styles/TopUpModal.module.scss";
import { ReactComponent as TopUpIcon } from "./../../assets/icons/topup.svg";
import Input from "../input";
import Button from "../button";
import classNames from "classnames";
import PayModal from "./payModal";
import useShow from "../../hooks/useShow";

import { createTransfer, parseURL, TransferRequestURL } from "@solana/pay";
import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import BigNumber from "bignumber.js";
import { selectCredit, fetchBalance } from "../../store/profileSlice";
import { fetchPayments } from "../../store/activitySlice";
import {
  confirmTopUpPayment,
  checkPaymentStatus,
  createPayment,
} from "../../api/paymetsApi";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { Transaction } from "@solana/web3.js";
import { useMetamask } from "../../providers/MetamaskProvider";

function TopUpModal({ show, onClose }: ModalContainerProps) {
  const credit = useAppSelector(selectCredit);
  const { connection } = useConnection();
  const { publicKey: payer, signTransaction } = useWallet();
  const { provider } = useMetamask();
  const [showPayModal, { show: handleShowPayModal, hide: handleHidePayModal }] =
    useShow();

  const [value, setValue] = useState<number | string>("");
  const dispatch = useAppDispatch();

  const topUp = useCallback(async () => {
    if (!provider) {
      if (!payer || !signTransaction) return;

      const { data } = await createPayment(Number(value));
      const { recipient, splToken, reference, amount, memo } = parseURL(
        data.payment_url
      ) as TransferRequestURL;

      const tx = await createTransfer(connection, payer, {
        recipient: recipient,
        amount: amount as BigNumber,
        reference,
        memo,
        splToken,
      });

      tx.recentBlockhash = (await connection.getLatestBlockhash()).blockhash;
      tx.feePayer = payer;

      const signedTransaction = await signTransaction(tx);
      return {
        tracking_code: data.tracking_code,
        tx: signedTransaction,
      };
    } else {
      console.log(provider);
      // const parsedAmount = parseEther(value.toString());
      // await setTransaction({
      //   from: accounts[0],
      //   to: "0x2f318C334780961FB129D2a6c30D0763d9a5C970",
      //   value: `0x${(Number(value) * 1e18).toString(16)}`,
      //   // gasPrice: '0x09184e72a000',
      //   // gas: '0x2710',
      // });
    }
  }, [connection, payer, provider, signTransaction, value]);

  const handlePayModalClose = useCallback(() => {
    handleHidePayModal();
    setTimeout(() => setValue(""), 100);
  }, [handleHidePayModal]);

  const handleConfirm = useCallback(
    async (tcode: string) => {
      return await confirmTopUpPayment(tcode, Number(value));
    },
    [value]
  );

  const handleStatus = useCallback(async (signature: string) => {
    return await checkPaymentStatus(signature);
  }, []);

  const handleSendTransaction = useCallback(
    async (tx: Transaction) => {
      const signature = await connection.sendRawTransaction(tx.serialize());
      return signature;
    },
    [connection]
  );

  const handleAfterPay = useCallback(() => {
    dispatch(fetchBalance());
    dispatch(fetchPayments());
  }, [dispatch]);

  return (
    <>
      <PayModal
        show={showPayModal}
        onClose={handlePayModalClose}
        amount={Number(value)}
        onPay={topUp}
        type="topup"
        confirmTx={handleConfirm}
        statusTx={handleStatus}
        onAfterPay={handleAfterPay}
        sendTransaction={handleSendTransaction}
      />
      <Modal
        title={"Top up"}
        bodyClassName={styles.body}
        show={show}
        onClose={onClose}
      >
        <div className="flex justify-center py-4 text-primary-500">
          <TopUpIcon width={100} height={100} />
        </div>
        <div className={styles.desc}>
          <p>
            Your Credit shows <span>{credit || 0} DJIB</span>{" "}
          </p>
          <p>Top up your credit to Avoid extra network fees</p>
        </div>
        <Input
          containerClassName="mt-2"
          type={"djib"}
          onChange={(e) => {
            if (e.target.value === "-") {
              setValue("");
            } else if (Number(e.target.value) < 0) {
              setValue("");
            } else setValue(e.target.value);
          }}
          value={value}
        />
        <div className={classNames(styles.newCredit, "mt-2")}>
          Total balance after top up:{" "}
          <span>{credit || 0 + Number(value)} DJIB</span>
        </div>
        <Button
          className="mt-4"
          fullWidth={true}
          withLoading={true}
          isDisabled={!value}
          onClick={() => {
            handleShowPayModal();
            onClose();
          }}
        >
          Proceed
        </Button>
      </Modal>
    </>
  );
}

export default TopUpModal;
