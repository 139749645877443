import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  Workflow,
  WorkflowActions,
  WorkflowStateType,
  WorkflowStep,
  WorkflowStepApiType,
} from "../types/workflow";
import {
  getWorkflowApi,
  getWorkflowsApi,
  getDeliveryMethodsApi,
  getAllowedActionsApi,
  createWorkflowApi,
  updateWorkflowApi,
  deleteWorkflowApi,
} from "../api/workflowApi";
import { RootState } from ".";
import { toast } from "react-hot-toast";
import { checkSignersInWorkflowSteps } from "../utils/helper";
import { Contact } from "../types/contacts";

const DevelopMode = false;

const initialState: WorkflowStateType = {
  loading: false,
  listLoading: false,
  workflows: [],
  workflowsPagination: {
    pageNumber: 1,
    pageSize: 10,
    total: 0,
  },
  deliveryMethods: [],
  allowedActions: [],
  showWorkflowStepsModal: false,
  showWorkflowSignersModal: false,
  selectedWorkflow: null,
  workflowStatusLoading: false,
  workflowStatusMessage: null,
  showEstimateModal: false,
  showWorkflowStatusModal: false,
};

export const initWorkflowAsync = createAsyncThunk(
  "workflow/init",
  async (_, thunkAPI) => {
    const { workflow } = thunkAPI.getState() as RootState;
    let preDeliveries = workflow.deliveryMethods;
    let preActions = workflow.allowedActions;

    if (preDeliveries.length === 0 || preActions.length === 0) {
      const res = await Promise.all([
        getDeliveryMethodsApi(),
        getAllowedActionsApi(),
      ]);
      preDeliveries = res[0].data;
      preActions = res[1].data;
    }

    return {
      deliveryMethods: preDeliveries,
      allowedActions: preActions,
    };
  }
);

export const createWorkflowAsync = createAsyncThunk(
  "workflow/create",
  async ({ fileId }: { fileId: number }, thunkAPI) => {
    if (!DevelopMode) {
      const { data } = await createWorkflowApi(fileId);

      return {
        ...data,
        WorkFlowSteps: [],
      } as Workflow;
    } else {
      await new Promise((resolve) => {
        setTimeout(() => {
          resolve("ok");
        }, 2000);
      });
      return {
        Id: 6,
        FileId: 2,
        UpdatedAt: new Date().toISOString(),
        WorkFlowSteps: [],
      } as Workflow;
    }
  }
);

export const updateWorkflowAsync = createAsyncThunk(
  "workflow/update",
  async (_, thunkAPI) => {
    const { workflow } = thunkAPI.getState() as RootState;
    if (workflow.selectedWorkflow !== null) {
      if (DevelopMode) {
        return workflow.selectedWorkflow;
      } else {
        const items = workflow.selectedWorkflow.WorkFlowSteps;
        const { data, status } = await updateWorkflowApi({
          id: workflow.selectedWorkflow.Id,
          steps: items.map(
            (item, index) =>
              ({
                ActionType: item.ActionType,
                ContactId: item.ContactId,
                Deliverymethod: item.Deliverymethod,
                OrderIndex: index,
              } as WorkflowStepApiType)
          ),
        });
        if (status) return thunkAPI.fulfillWithValue(data);
        else return thunkAPI.rejectWithValue({});
      }
    } else {
      return thunkAPI.rejectWithValue("There is not any selected workflow");
    }
  }
);

export const getWorkflowAsync = createAsyncThunk(
  "workflow/get",
  async ({ id }: { id: number }, thunkAPI) => {
    const { data } = await getWorkflowApi(id);
    return data as Workflow;
  }
);

export const getWorkflowsAsync = createAsyncThunk(
  "workflows/get",
  async (_, { rejectWithValue, getState }) => {
    const { workflow } = getState() as RootState;
    const { data, status } = await getWorkflowsApi(
      workflow.workflowsPagination.pageNumber,
      workflow.workflowsPagination.pageSize
    );
    if (status) {
      return data;
    }
    return rejectWithValue("error");
  }
);

export const deleteWorkflowsAsync = createAsyncThunk(
  "workflows/delete",
  async ({ workflowId }: { workflowId: number }) => {
    const { message } = await deleteWorkflowApi({ workflowId });
    return message;
  }
);

const workflowSlice = createSlice({
  name: "workflow",
  initialState,
  reducers: {
    handleHideWorkflowStepsModal: (state) => {
      state.showWorkflowStepsModal = false;
    },
    handleShowWorkflowStepsModal: (state) => {
      state.showWorkflowStepsModal = true;
    },
    handleHideWorkflowSignersModal: (state) => {
      state.showWorkflowSignersModal = false;
    },
    handleShowWorkflowSignersModal: (state) => {
      state.showWorkflowSignersModal = true;
    },
    handleHideEstimateModal: (state) => {
      state.showEstimateModal = false;
      state.selectedWorkflow = null;
    },
    handleShowEstimateModal: (state) => {
      state.showWorkflowSignersModal = false;
      state.showEstimateModal = true;
    },
    handleShowStatusModal: (state) => {
      // state.selectedWorkflow = false;
      state.showWorkflowStatusModal = true;
    },
    handleHideStatusModal: (state) => {
      state.showWorkflowStatusModal = false;
      state.selectedWorkflow = null;
    },
    handleAddWorkflowStep: (state) => {
      const newItem: WorkflowStep = {
        Id: Math.random() * 10000,
        Deliverymethod: state.deliveryMethods[0].Value,
        ActionType: state.allowedActions[0].Value as WorkflowActions,
        ActionStatus: 0,
        ContactId: 0,
        OrderIndex: 0,
        Positions: [],
      };
      if (state.selectedWorkflow !== null) {
        state.selectedWorkflow.WorkFlowSteps = [
          ...state.selectedWorkflow.WorkFlowSteps,
          newItem,
        ];
      }
    },
    handleDeleteWorkflowStep: (state, action) => {
      if (state.selectedWorkflow !== null) {
        state.selectedWorkflow.WorkFlowSteps =
          state.selectedWorkflow.WorkFlowSteps.filter(
            (item) => item.Id !== action.payload
          );
      }
    },
    handleUpdateStepDelivery: (
      state,
      action: { payload: { stepId: number; deliveryId: number } }
    ) => {
      if (state.selectedWorkflow) {
        state.selectedWorkflow.WorkFlowSteps =
          state.selectedWorkflow.WorkFlowSteps.map((step) =>
            step.Id === action.payload.stepId
              ? ({
                  ...step,
                  Deliverymethod: action.payload.deliveryId,
                } as WorkflowStep)
              : step
          );
      }
    },
    handleUpdateStepAction: (
      state,
      action: { payload: { stepId: number; actionId: number } }
    ) => {
      if (state.selectedWorkflow) {
        state.selectedWorkflow.WorkFlowSteps =
          state.selectedWorkflow.WorkFlowSteps.map((step) =>
            step.Id === action.payload.stepId
              ? ({
                  ...step,
                  ActionType: action.payload.actionId,
                } as WorkflowStep)
              : step
          );
      }
    },
    handleUpdateStepContact: (
      state,
      action: { payload: { stepId: number; contactId: number } }
    ) => {
      if (state.selectedWorkflow) {
        state.selectedWorkflow.WorkFlowSteps =
          state.selectedWorkflow.WorkFlowSteps.map((step) =>
            step.Id === action.payload.stepId
              ? ({
                  ...step,
                  ContactId: action.payload.contactId,
                } as WorkflowStep)
              : step
          );
      }
    },
    handleReorder: (state, action) => {
      if (
        state.selectedWorkflow !== null &&
        state.selectedWorkflow.WorkFlowSteps.length > 0
      )
        state.selectedWorkflow.WorkFlowSteps = action.payload;
    },
    handleAddMe: (state, action: { payload: { me: Contact | undefined } }) => {
      const me = action.payload.me;
      if (state.selectedWorkflow !== null && me !== undefined) {
        const newItem: WorkflowStep = {
          Id: Math.random() * 100000,
          ContactId: me.Id,
          ActionType: state.allowedActions[0].Value as WorkflowActions,
          ActionStatus: 0,
          Deliverymethod: state.deliveryMethods[0].Value,
          OrderIndex: 0,
          Positions: [],
        };
        state.selectedWorkflow.WorkFlowSteps = [
          ...state.selectedWorkflow.WorkFlowSteps,
          newItem,
        ];
      }
    },
    selectWorkflow: (state, action: { payload: Workflow }) => {
      state.selectedWorkflow = action.payload;
    },
    clearSelectedWorkflow: (state) => {
      state.selectedWorkflow = null;
      state.loading = false;
    },
    changePage(state, action: { payload: { page: number } }) {
      state.workflowsPagination = {
        ...state.workflowsPagination,
        pageNumber: action.payload.page,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(initWorkflowAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(initWorkflowAsync.fulfilled, (state, action) => {
        state.deliveryMethods = action.payload.deliveryMethods;
        state.allowedActions = action.payload.allowedActions;
        state.loading = false;
      })
      .addCase(initWorkflowAsync.rejected, (state, action) => {
        toast.error("network error");
        state.loading = false;
      });

    builder
      .addCase(getWorkflowAsync.pending, (state) => {
        state.workflowStatusLoading = true;
        state.workflowStatusMessage = "Get Workflow Data...";
      })
      .addCase(getWorkflowAsync.fulfilled, (state, action) => {
        state.selectedWorkflow = action.payload;
        state.workflowStatusLoading = false;
        state.workflowStatusMessage = null;
      })
      .addCase(getWorkflowAsync.rejected, (state, action) => {
        toast.error("network error");
        state.workflowStatusLoading = false;
        state.workflowStatusMessage = "";
      });

    builder
      .addCase(getWorkflowsAsync.pending, (state) => {
        state.listLoading = true;
      })
      .addCase(getWorkflowsAsync.fulfilled, (state, action) => {
        state.workflows = action.payload.workflows;
        const { PageNumber, Total } = action.payload.pagination;
        state.workflowsPagination = {
          ...state.workflowsPagination,
          pageNumber: PageNumber,
          total: Total,
        };
        state.listLoading = false;
      })
      .addCase(getWorkflowsAsync.rejected, (state, action) => {
        toast.error("network error");
        state.listLoading = false;
      });

    builder
      .addCase(createWorkflowAsync.pending, (state) => {
        state.workflowStatusLoading = true;
        state.workflowStatusMessage = "Creating Workflow...";
        state.showWorkflowStepsModal = true;
      })
      .addCase(createWorkflowAsync.fulfilled, (state, action) => {
        state.selectedWorkflow = action.payload;
        state.workflowStatusLoading = false;
        state.workflowStatusMessage = null;
      })
      .addCase(createWorkflowAsync.rejected, (state, action) => {
        toast.error("network error");
        state.workflowStatusLoading = false;
        state.workflowStatusMessage = "";
        state.showWorkflowStepsModal = false;
      });

    builder
      .addCase(updateWorkflowAsync.pending, (state) => {
        state.workflowStatusLoading = true;
        state.workflowStatusMessage = "Add Steps to Workflow...";
      })
      .addCase(updateWorkflowAsync.fulfilled, (state, action) => {
        state.selectedWorkflow = action.payload;
        state.workflowStatusLoading = false;
        state.workflowStatusMessage = null;
        state.showWorkflowStepsModal = false;
        if (checkSignersInWorkflowSteps(action.payload)) {
          state.showWorkflowSignersModal = true;
        } else {
          state.showEstimateModal = true;
        }
      })
      .addCase(updateWorkflowAsync.rejected, (state, action) => {
        toast.error("network error");
        state.workflowStatusLoading = false;
        state.workflowStatusMessage = "";
        // state.showWorkflowStepsModal = false;
      });

    builder
      .addCase(deleteWorkflowsAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteWorkflowsAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.selectedWorkflow = null;
      })
      .addCase(deleteWorkflowsAsync.rejected, (state, action) => {
        toast.error("network error");
        state.loading = false;
        state.selectedWorkflow = null;
      });
  },
});

export const selectWorkflows = (state: RootState) => state.workflow.workflows;

export const selectWorkflowsPagination = (state: RootState) =>
  state.workflow.workflowsPagination;

export const selectWorkflowListLoading = (state: RootState) =>
  state.workflow.listLoading;

export const selectDeliveryMethods = (state: RootState) =>
  state.workflow.deliveryMethods;

export const selectAllowedActions = (state: RootState) =>
  state.workflow.allowedActions;

export const selectWorkflowInitLoading = (state: RootState) =>
  state.workflow.loading;

export const selectSelectedWorkflow = (state: RootState) =>
  state.workflow.selectedWorkflow;

export const selectShowWorkflowStepsModal = (state: RootState) =>
  state.workflow.showWorkflowStepsModal;

export const selectShowWorkflowSignersModal = (state: RootState) =>
  state.workflow.showWorkflowSignersModal;

export const selectWorkflowStatusLoading = (state: RootState) =>
  state.workflow.workflowStatusLoading;

export const selectWorkflowStatusMessage = (state: RootState) =>
  state.workflow.workflowStatusMessage;

export const selectShowEstimateModal = (state: RootState) =>
  state.workflow.showEstimateModal;

export const selectShowWorkflowStatusModal = (state: RootState) =>
  state.workflow.showWorkflowStatusModal;

export const {
  handleHideWorkflowStepsModal,
  handleShowWorkflowStepsModal,
  handleHideWorkflowSignersModal,
  handleShowWorkflowSignersModal,
  handleAddWorkflowStep,
  handleDeleteWorkflowStep,
  handleUpdateStepDelivery,
  handleUpdateStepAction,
  handleUpdateStepContact,
  handleReorder,
  handleAddMe,
  selectWorkflow,
  clearSelectedWorkflow,
  handleShowEstimateModal,
  handleHideEstimateModal,
  changePage,
  handleHideStatusModal,
  handleShowStatusModal,
} = workflowSlice.actions;
export default workflowSlice.reducer;
