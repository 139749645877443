import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import PdfSigner from "../components/pdf/pdfSigner";
import SignMethodDropdown, {
  ListboxOption,
  signMethods,
} from "./../components/pdf/tools/signMethodDropdown";
import { getStepApi, signApi } from "../api/workflowApi";
import PaintSignImage from "../components/pdf/tools/paintSignImage";
import UploadSignImage from "../components/pdf/tools/uploadSignImage";
import CreateSignImage from "../components/pdf/tools/createSignImage";
import WalletModal from "../components/walletModal";
import { AnnotationItemType } from "../types/pdf";
import { createPDF } from "./../components/pdf/tools/maker";
// import { saveAs } from "file-saver";
import { useWallet } from "@solana/wallet-adapter-react";
import { useMetamask } from "../providers/MetamaskProvider";
import { getStorage, setStorage } from "../utils/helper";
import Loading from "../components/loading";
import { StepDataType } from "../types/workflow";
import { ReactComponent as NotFindIcon } from "./../assets/icons/404.svg";
import { ReactComponent as SuccessIcon } from "./../assets/icons/success2.svg";

export default function SignFilePage() {
  const [loading, setLoading] = useState(true);
  const [status, setStatus] = useState<{
    type: "error" | "success";
    message?: string;
  } | null>(null);
  const { token } = useParams();
  const [selectedSignMethod, setSelectedSignMethod] = useState<ListboxOption>(
    () => {
      const selectedSignType = getStorage("selectedSignType");
      if (selectedSignType) {
        return (
          signMethods.find((sm) => sm.value === selectedSignType) ||
          signMethods[0]
        );
      } else {
        setStorage("selectedSignType", signMethods[0].value);
        return signMethods[0];
      }
    }
  );
  const [stepData, setStepData] = useState<StepDataType | null>(null);
  const [value, setValue] = useState<string | undefined>(undefined);
  const [valueType, setValueType] = useState<"text" | "image" | undefined>(
    undefined
  );

  const { connected } = useWallet();
  const { isConnected } = useMetamask();

  useEffect(() => {
    const initStepData = async (token: string) => {
      const res = await getStepApi({ token });
      if (res.status) {
        if (res.data) {
          setStepData(res.data);
        }
      } else {
        setStatus({ type: "error", message: res.message });
      }
      console.log(res);
      setLoading(false);
    };
    if (token) {
      initStepData(token);
    }
  }, [token]);

  const handleSelectedMethod = (option: ListboxOption) => {
    setValueType(undefined);
    setValue(undefined);
    setStorage("selectedSignType", option.value);
    setSelectedSignMethod(option);
  };

  const handleChangeImage = (image: string) => {
    setValueType("image");
    setValue(image);
  };

  // const handleChangeSignature = (text: string) => {
  //   setValueType("text");
  //   setValue(text);
  // };

  const handleSignDocument = async () => {
    if (
      stepData !== null &&
      stepData.StepComponents &&
      value !== undefined &&
      token
    ) {
      const { blob, filename } = await createPDF(
        stepData.FileLink + "?token=" + token,
        value,
        positions
      );
      const formData = new FormData();
      formData.append("file", blob, filename);
      formData.append("token", token);
      const res = await signApi(formData);
      console.log("res:", res);
      if (res.status) {
        setStatus({ type: "success", message: "File signed successfully" });
      } else {
        setStatus({ type: "error", message: "File Not signed" });
      }
      // saveAs(blob, filename);
    }
  };

  const positions: Array<AnnotationItemType> =
    stepData?.StepComponents.map(
      (s) =>
        ({
          id: s.Id,
          page: s.PageNum,
          left: s.XCoor,
          bottom: s.YCoor,
          type: "show",
          value,
          valueType,
        } as AnnotationItemType)
    ) || [];

  // if (token === undefined) window.location.href = "/";
  if (loading)
    return <Loading style={{ height: "100%", marginTop: "200px" }} />;
  if (status !== null)
    return (
      <div className="w-full h-full flex flex-col justify-center items-center bg-white mt-20">
        {status.type === "error" && (
          <NotFindIcon className="max-w-sm min-w-md" />
        )}
        {status.type === "success" && (
          <SuccessIcon className="max-w-sm min-w-md" />
        )}
        <span className="text-slate-500 font-normal text-xl mt-4">
          {status.message}
        </span>
      </div>
    );
  return (
    <div className="flex flex-col md:flex-row justify-center gap-12 md:gap-20 py-8 px-10">
      <div className="w-full md:w-[300px] lg:w-[300px]">
        <h1 className="mb-3 text-2xl text-slate-600 font-semibold">
          Sign Your Doc
        </h1>
        <span className="text-sm text-slate-500">Select sign method:</span>
        <SignMethodDropdown
          options={signMethods}
          selected={selectedSignMethod}
          setSelected={handleSelectedMethod}
        />
        {selectedSignMethod.value === "sign" && (
          <PaintSignImage
            onSign={handleSignDocument}
            onChange={handleChangeImage}
          />
        )}
        {selectedSignMethod.value === "signature" && (
          <div className="mt-8">
            {connected || isConnected ? (
              <CreateSignImage
                onSign={handleSignDocument}
                onChange={handleChangeImage}
              />
            ) : (
              <WalletModal absolute={true} />
            )}
          </div>
        )}
        {selectedSignMethod.value === "image" && (
          <UploadSignImage
            onSign={handleSignDocument}
            onChange={handleChangeImage}
          />
        )}
      </div>
      <div className="md:w-[500px]">
        {stepData && (
          <PdfSigner
            path={stepData.FileLink + "?token=" + token}
            width={window.innerWidth > 600 ? 450 : 300}
            positions={positions}
          />
        )}
      </div>
    </div>
  );
}
