import Modal, { ModalContainerProps } from "./modal";
import styles from "./../../styles/TopUpModal.module.scss";
import Button from "../button";
import { deleteFile, selectSelectedFile } from "../../store/filesSlice";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { toast } from "react-hot-toast";

function FileDeleteModal({
  show,
  onClose,
  onCallback,
}: ModalContainerProps & { onCallback?: () => void }) {
  const file = useAppSelector(selectSelectedFile);
  const dispatch = useAppDispatch();

  return (
    <Modal
      title={"File Delete"}
      bodyClassName={styles.body}
      show={show}
      onClose={onClose}
    >
      <div className="flex justify-center py-4 text-primary-500 flex-col gap-4">
        <div className="flex w-full">Are you sure to delete this file?</div>
        <div className="flex w-full justify-end">
          {file && (
            <Button
              withLoading
              color="danger"
              onClick={() =>
                dispatch(deleteFile({ fileId: file.Id })).then((action) => {
                  console.log(action);
                  onClose();
                  if (action.type === "files/delete/rejected") {
                    toast.error(
                      (action.payload as { message: string }).message
                    );
                  }
                  if (action.type === "files/delete/fulfilled") {
                    toast.success(action.payload as string);
                    onCallback && onCallback();
                  }
                })
              }
            >
              Yes
            </Button>
          )}
        </div>
      </div>
    </Modal>
  );
}

export default FileDeleteModal;
