import { useState } from "react";
import Loading from "../loading";
import Modal, { ModalContainerProps } from "./modal";
import PdfReader from "../pdf/pdfReader";
import {
  selectSelectedFile,
  selectSelectingLoading,
} from "../../store/filesSlice";
import { useAppSelector } from "../../hooks";
import { toast } from "react-hot-toast";
export type FileViewerProps = ModalContainerProps;

function PDFViewerModal({ show, onClose }: FileViewerProps) {
  const selectedFile = useAppSelector(selectSelectedFile);
  const selectedFileLoading = useAppSelector(selectSelectingLoading);

  const readerHeight = 500;
  const [readerWidth, setReaderWidth] = useState(388);

  return (
    <Modal
      title={"View file"}
      bodyStyle={{
        minHeight: readerHeight,
        maxHeight: "100vh",
      }}
      containerStyle={{ alignItems: "flex-start", overflowY: "auto" }}
      bodyClassName="w-full max-h-screen transition-all duration-1000"
      show={show}
      onClose={onClose}
    >
      <div
        className="h-[2px] rounded-full bg-sky-400"
        style={{ width: readerWidth }}
      ></div>
      <div
        className=""
        style={{
          height: readerHeight + 34,
          width: readerWidth,
          overflowY: "auto",
        }}
      >
        {selectedFileLoading && (
          <div className="w-full h-full">
            <Loading size={18} borderSize={2} />
          </div>
        )}

        {!selectedFileLoading && selectedFile && (
          <PdfReader
            // height={readerHeight}
            width={readerWidth}
            onPageLoadSuccess={(w: number | undefined) =>
              w && setReaderWidth(Math.floor(w))
            }
            path={
              selectedFile?.Path ||
              "https://www.antennahouse.com/hubfs/xsl-fo-sample/pdf/basic-link-1.pdf"
            }
          />
        )}
      </div>
    </Modal>
  );
}

export default PDFViewerModal;
