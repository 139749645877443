import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { ActivityStateType, PaymentType } from "../types/activity";
import { RootState } from ".";
import { getPayments } from "../api/paymetsApi";

const initialState: ActivityStateType = {
  loading: true,
  error: false,
  body: {
    payments: [],
    paginations: {
      pageNumber: 1,
      pageSize: 5,
      total: 0,
    },
  },
};

export const fetchPayments = createAsyncThunk(
  "activity/fetchPayments",
  async (_, { getState }) => {
    const { activity } = getState() as RootState;
    let { pageNumber, pageSize } = activity.body.paginations;
    const { data } = await getPayments(pageNumber, pageSize);
    const payments: PaymentType[] = [
      ...data.activities.map(
        (ac) =>
          ({
            price: ac.Amount,
            status: ac.Status,
            signature: ac.Signature,
            updated_at: ac.UpdatedAt,
            type: ac.Type,
          } as PaymentType)
      ),
    ];
    return {
      payments: payments,
      pagination: data.pagination,
    };
  }
);

const activitySlice = createSlice({
  name: "activity",
  initialState: initialState,
  reducers: {
    clearActivity(state) {
      state.body = initialState.body;
      state.loading = initialState.loading;
      state.error = initialState.error;
    },
    changePage(state, action: { payload: { page: number } }) {
      state.body.paginations = {
        ...state.body.paginations,
        pageNumber: action.payload.page,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPayments.fulfilled, (state, action) => {
      state.body.payments = action.payload.payments;
      const { PageNumber, Total } = action.payload.pagination;
      state.body.paginations = {
        ...state.body.paginations,
        pageNumber: PageNumber,
        total: Total,
      };
      state.loading = false;
      state.error = false;
    });

    builder.addCase(fetchPayments.pending, (state, action) => {
      state.loading = true;
      state.error = false;
    });

    builder.addCase(fetchPayments.rejected, (state, action) => {
      state.loading = false;
      state.error = true;
    });
  },
});

export const selectLoading = (state: RootState) => state.activity.loading;
export const selectActivity = (state: RootState) => state.activity.body;
export const { clearActivity, changePage } = activitySlice.actions;

export default activitySlice.reducer;
