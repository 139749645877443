import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  fetchPayments,
  selectLoading,
  selectActivity,
  changePage,
} from "../store/activitySlice";
import styles from "./../styles/RecentActivity.module.scss";
import { ReactComponent as TopUpIcon } from "./../assets/icons/topup.svg";
import { ReactComponent as SuccessIcon } from "./../assets/icons/tic.svg";
import { ReactComponent as FailedIcon } from "./../assets/icons/close.svg";
import { ReactComponent as ActivityIcon } from "./../assets/icons/activity.svg";
import Loading from "./loading";
import classNames from "classnames";
import Pagination from "./pagination";
import { dateFormat, convertDate } from "../utils/helper";
import { useAppDispatch, useAppSelector } from "../hooks";
import { PaymentStatusType } from "../types/activity";
import useScrollToTop from "../hooks/useScrollToTop";

function Item({
  status,
  date,
  price,
  type,
  signture,
  message,
  method,
  amount,
}: {
  status: PaymentStatusType;
  type: "topup";
  date: string;
  price: number;
  amount?: number;
  signture: string;
  message?: string;
  method?: "CREDIT" | "PAYMENT";
}) {
  const name = useMemo(() => {
    if (type === "topup") {
      return `Wallet top up for ${price} DJIB`;
    }
  }, [price, type]);

  return (
    <a
      className={styles.item}
      {...(signture && {
        target: "_blank",
        href: `https://solscan.io/tx/${signture}${
          process.env.REACT_APP_PUBLIC_NETWORK !== "mainnet-beta"
            ? `?cluster=${process.env.REACT_APP_PUBLIC_NETWORK}`
            : ""
        }`,
      })}
      rel="noreferrer"
    >
      {message && (
        <div
          className={classNames(styles.messageContainer, {
            [styles.ok]: status === "Success",
            [styles.pending]: status === "Pending",
            [styles.error]: status === "Failed",
          })}
        >
          {message}
        </div>
      )}

      <div className={styles.icon}>
        {type === "topup" && <TopUpIcon />}
        <span
          className={classNames({
            [styles.ok]: status === "Success",
            [styles.pending]: status === "Pending",
            [styles.error]: status === "Failed",
          })}
        >
          {status === "Success" && <SuccessIcon />}
          {status === "Failed" && <FailedIcon />}
          {status === "Pending" && (
            <Loading
              size={12}
              borderSize={2}
              color={"#fff"}
              borderColor={"#ffffff40"}
            />
          )}
        </span>
      </div>
      <div className={styles.title}>
        <p className={styles.name}>{name}</p>
        {date !== "None" && (
          <p className={styles.date}>{dateFormat(convertDate(date))}</p>
        )}
      </div>
      <p className={styles.price}>
        {price} <span>DJIB</span>
      </p>
    </a>
  );
}

function RecentActivity() {
  const { payments, paginations } = useAppSelector(selectActivity);
  const loading = useAppSelector(selectLoading);
  const dispatch = useAppDispatch();
  const scrollToTop = useScrollToTop({ behavior: "smooth" });

  useEffect(() => {
    dispatch(fetchPayments());
    scrollToTop();
  }, [dispatch, paginations.pageNumber, paginations.pageSize, scrollToTop]);

  const handleChangePage = useCallback(
    (n: number) => {
      if (n > 0 && n < 200) {
        dispatch(changePage({ page: n }));
      }
    },
    [dispatch]
  );

  return (
    <div className="py-8 px-10 md:px-0">
      <p className="text-lg text-slate-600">Recent Activity</p>
      <div className="bg-slate-50 md:mr-12 rounded-2xl p-6 mt-4 flex flex-col gap-4">
        {!loading &&
          payments.map((item) => (
            <Item
              key={`payment-${item.updated_at}`}
              status={item.status}
              price={item.price}
              amount={item.amount}
              signture={item.signature}
              message={item.message}
              method={item.method}
              type={"topup"}
              date={item.updated_at}
            />
          ))}
        {!loading && !payments.length && (
          <div
            className="flex flex-col justify-center items-center"
            style={{ height: 5 * 110 }}
          >
            <ActivityIcon className="w-28 h-28" color={"#cbd5e1"} />
            <p className="text-slate-300 text-medium">No activity</p>
          </div>
        )}
        {loading && (
          <div
            className="flex justify-center items-center"
            style={{ height: 5 * 110 }}
          >
            <Loading />
          </div>
        )}
      </div>

      <div className="flex justify-center py-8 mb-12">
        {(paginations.total || 0) > 1 && (
          <Pagination
            totalCount={paginations.total || 0}
            currentPage={paginations.pageNumber}
            pageSize={paginations.pageSize}
            onPageChange={handleChangePage}
          />
        )}
      </div>
    </div>
  );
}

export default RecentActivity;
