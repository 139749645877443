import { AuthFetch, FetchCatchResponse, FetchResponse } from "./index";
import type {
  ProfileApiResponce,
  ProfileImageApiResponce,
  ProfileBalanceApiResponce,
} from "../types/profile";

export type GetProfile = {
  Id: number;
  Balance: number;
};

export async function getProfileApi(): Promise<
  FetchResponse<ProfileApiResponce>
> {
  try {
    const { data } = await AuthFetch(false).get(`/user`);
    return {
      data: data.Result,
      status: true,
      message: data.Message,
    };
  } catch (e) {
    return FetchCatchResponse(e);
  }
}

export async function saveProfileApi(
  name: string,
  email: string
): Promise<FetchResponse<ProfileApiResponce>> {
  try {
    const { data } = await AuthFetch(false).put(`/user/personal-data`, {
      Name: name,
      Email: email,
    });
    return {
      data: data.Result,
      status: true,
      message: data.Message,
    };
  } catch (e) {
    return FetchCatchResponse(e);
  }
}

export async function getProfileImageApi(): Promise<any> {
  return await AuthFetch(false).get(`/user/avatar`, { responseType: "blob" });
}

export async function saveProfileImageApi(
  formData: any
): Promise<FetchResponse<ProfileImageApiResponce>> {
  try {
    const { data } = await AuthFetch(false).put(`/user/image`, formData);
    return {
      data: data.Result,
      status: true,
      message: data.Message,
    };
  } catch (e) {
    return FetchCatchResponse(e);
  }
}

export async function getBalanceApi(): Promise<
  FetchResponse<ProfileBalanceApiResponce>
> {
  try {
    const { data } = await AuthFetch(false).get(`/user/balance`);
    return {
      data: data.Result,
      status: true,
      message: data.Message,
    };
  } catch (e) {
    return FetchCatchResponse(e);
  }
}
