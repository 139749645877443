import React, { useState } from "react";
import SignPlaceAnnotation from "./signPlaceAnnotation";
import { AnnotationItemType } from "../../../types/pdf";
import { getMousePositions } from "../../../utils/helper";
import SignShowAnnotation from "./signShowAnnotation";

export default function AnnotationLoader({
  page,
  items,
  onDelete,
  onUpdate,
  onUpdating,
}: {
  page: number;
  items: AnnotationItemType[];
  onDelete?: (id: number) => void;
  onUpdate?: (item: AnnotationItemType) => void;
  onUpdating?: (item: AnnotationItemType) => void;
}) {
  let offsetX = 0;
  let offsetY = 0;
  const [position, setPosition] = useState({ x: 0, y: 0 });

  function handleMouseEnter(event: any) {
    console.log("mouse enter!");
    // setPosition({ x: event.clientX, y: event.clientY });
  }

  function handleMouseLeave(event: any) {
    console.log("mouse leave!");
    // setPosition({ x: event.clientX, y: event.clientY });
  }

  const handleMouseDown = (
    id: number,
    event: React.MouseEvent<HTMLDivElement>
  ) => {
    const selectedItem = items.find((item) => item.id === id);
    if (selectedItem) {
      console.log(event.pageX);
      // const res = getMousePositions(event);
      // console.log(res);
      // offsetX = event.clientX - selectedItem.left;
      // offsetY = window.innerHeight - event.clientY - selectedItem.bottom;
      // document.addEventListener("mousemove", (e) => handleMouseMove(id, e));
      // document.addEventListener("mouseup", (e) => handleMouseUp(id, e));
    }
  };

  const handleMouseMove = (id: number, event: MouseEvent) => {
    const selectedItem = items.find((item) => item.id === id);
    if (selectedItem) {
      const newLeft = event.clientX - offsetX;
      const newBottom = window.innerHeight - event.clientY - offsetY;
      if (typeof onUpdating === "function")
        onUpdating({
          id: selectedItem.id,
          page: selectedItem.page,
          type: selectedItem.type,
          left: newLeft,
          bottom: newBottom,
        } as AnnotationItemType);
    }
  };

  const handleMouseUp = (id: number, event: MouseEvent) => {
    const selectedItem = items.find((item) => item.id === id);
    if (selectedItem) {
      const newLeft = event.clientX - offsetX;
      const newBottom = window.innerHeight - event.clientY - offsetY;
      if (typeof onUpdate === "function")
        onUpdate({
          id: selectedItem.id,
          page: selectedItem.page,
          type: selectedItem.type,
          left: newLeft,
          bottom: newBottom,
        } as AnnotationItemType);
    }
    // document.removeEventListener("mousemove", handleMouseMove);
    // document.removeEventListener("mouseup", () => handleMouseUp(id, event));
  };

  const AnnotationItem = ({ item }: { item: AnnotationItemType }) => {
    if (item.type === "place" && page === item.page) {
      return (
        <SignPlaceAnnotation
          bottom={item.bottom}
          left={item.left}
          id={item.id}
          onDelete={onDelete}
        />
      );
    }
    if (item.type === "show" && page === item.page) {
      return (
        <SignShowAnnotation
          id={item.id}
          bottom={item.bottom}
          left={item.left}
          value={item.value}
          valueType={item.valueType}
        />
      );
    }
    return <></>;
  };

  return (
    <>
      {items.map((item, index) => (
        <AnnotationItem key={`item-${index}`} item={item} />
      ))}
    </>
  );
}
