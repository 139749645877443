import Combobox, { ComboboxOptionType } from "../combobox";
import { ReactComponent as DragIcon } from "../../assets/icons/drag-vertical.svg";
import { ReactComponent as DeleteIcon } from "../../assets/icons/delete.svg";
import Listbox, { ListboxOptionType } from "../listbox";
import { WorkflowStep } from "./../../types/workflow";
import { useAppDispatch, useAppSelector } from "../../hooks";
import {
  handleDeleteWorkflowStep,
  selectAllowedActions,
  selectDeliveryMethods,
  handleUpdateStepDelivery,
  handleUpdateStepAction,
  handleUpdateStepContact,
} from "../../store/workflowSlice";
import { handleShowContactsModal } from "../../store/contactsSlice";

type RecipientDragItemType = {
  contacts: Array<ComboboxOptionType> | [];
  recipient: WorkflowStep;
  order?: number;
  initLoading?: boolean;
};

const RecipientDragItem = ({
  contacts,
  initLoading,
  order,
  recipient,
}: RecipientDragItemType) => {
  const deliveryMethods = useAppSelector(selectDeliveryMethods);
  const allowedActions = useAppSelector(selectAllowedActions);
  const dispatch = useAppDispatch();

  return (
    <div className="relative flex justify-between items-center w-full border-2 p-3 pl-8 bg-white border-l-sky-400 border-l-4 rounded-l-md rounded-r-xl">
      <DragIcon className="w-5 h-5 absolute left-1 text-slate-600 hover:text-slate-800" />
      <div className="flex w-full flex-col md:flex-row md:justify-between items-start gap-4">
        <div className="w-full md:w-1/2">
          <span className="text-sm text-gray-600">
            Name <span className=" text-red-500 text-sm">*</span>
          </span>
          <Combobox
            options={contacts}
            loading={initLoading || false}
            selectedValue={recipient.ContactId}
            onChange={async (option: ComboboxOptionType) => {
              dispatch(
                handleUpdateStepContact({
                  stepId: recipient.Id,
                  contactId: option.value,
                })
              );
            }}
          />
          <span
            className="text-sm text-gray-500 hover:text-sky-500 cursor-pointer"
            onClick={() => dispatch(handleShowContactsModal())}
          >
            show contacts
          </span>
        </div>
        <div className="w-full md:w-1/2">
          <div className="flex gap-1">
            <div className="w-5/12">
              <Listbox
                options={allowedActions.map(
                  (d) =>
                    ({
                      label: d.Name,
                      value: d.Value,
                    } as ListboxOptionType)
                )}
                selectedValue={recipient?.ActionType}
                firstSelect={true}
                onChange={(option: ListboxOptionType) => {
                  dispatch(
                    handleUpdateStepAction({
                      actionId: option.value,
                      stepId: recipient.Id,
                    })
                  );
                }}
              />
            </div>
            <div className="w-5/12">
              <Listbox
                options={deliveryMethods.map(
                  (a) =>
                    ({
                      label: a.Name,
                      value: a.Value,
                    } as ListboxOptionType)
                )}
                firstSelect={true}
                selectedValue={recipient?.Deliverymethod}
                onChange={(option: ListboxOptionType) =>
                  dispatch(
                    handleUpdateStepDelivery({
                      stepId: recipient.Id,
                      deliveryId: option.value,
                    })
                  )
                }
              />
            </div>
            <div className="w-2/12 flex items-center justify-end">
              <DeleteIcon
                className="w-6 h-6 text-slate-600 hover:text-slate-800 cursor-pointer hover:bg-sky-100 p-1 rounded-lg"
                onClick={() => dispatch(handleDeleteWorkflowStep(recipient.Id))}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RecipientDragItem;
