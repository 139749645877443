import { Contact, ContactsCreateDataType } from "../types/contacts";
import { AuthFetch, FetchCatchResponse, FetchResponse } from "./index";

export async function getUserContactsApi(): Promise<
  FetchResponse<Array<Contact>>
> {
  try {
    const { data } = await AuthFetch().get(`/contact`);
    return {
      data: data.Result,
      status: true,
      message: data.Message,
    };
  } catch (e) {
    return FetchCatchResponse(e);
  }
}

export async function createContactsApi(
  formData: ContactsCreateDataType
): Promise<FetchResponse<Contact>> {
  try {
    const { data } = await AuthFetch().post(`/contact`, formData);
    return {
      data: data.Result,
      status: true,
      message: data.Message,
    };
  } catch (e) {
    return FetchCatchResponse(e);
  }
}

export async function deleteContactsApi(
  id: number
): Promise<FetchResponse<null>> {
  try {
    const { data } = await AuthFetch().delete(`/contact/${id}`);
    return {
      data: null,
      status: true,
      message: data.Message,
    };
  } catch (e) {
    return FetchCatchResponse(e);
  }
}
