import classNames from "classnames";
import { useCallback, useEffect, useState } from "react";
import styles from "./../styles/FileList.module.scss";
import File from "./file";
import Skeleton from "./skeleton";
import { ReactComponent as EmptyState } from "./../assets/icons/empty-state.svg";
import PDFViewerModal from "./modals/pdfViewerModal";
import WorkflowModal from "./modals/workflowModal";
import { SignerManagerModal } from "./modals/workflowModal";
import EstimateModal, { BalanceErrorModal } from "./modals/estimateModal";
import FileDeleteModal from "./modals/fileDeleteModal";
import { useAppDispatch, useAppSelector } from "../hooks";
import { selectLoading as selectProfileLoading } from "../store/profileSlice";
import type { FileType } from "../types/file";
import {
  selectFiles,
  selectLoading,
  getFile,
  clearSelectedFile,
  selectFile,
  fetchFiles,
} from "../store/filesSlice";
import useShow from "../hooks/useShow";
import {
  selectShowWorkflowStepsModal,
  handleHideWorkflowStepsModal,
  selectShowWorkflowSignersModal,
  handleHideWorkflowSignersModal,
  handleHideEstimateModal,
  selectShowEstimateModal,
} from "../store/workflowSlice";
import { useSearch } from "../providers/SearchProvider";

function FileList() {
  const files = useAppSelector(selectFiles);
  const [filteredFiles, setSearchedFiles] = useState<FileType[]>([]);
  const filesLoading = useAppSelector(selectLoading);
  const profileLoading = useAppSelector(selectProfileLoading);
  const showWorkflowStepsModal = useAppSelector(selectShowWorkflowStepsModal);
  const showWorkflowSignersModal = useAppSelector(
    selectShowWorkflowSignersModal
  );
  const showEstimateModal = useAppSelector(selectShowEstimateModal);
  const dispatch = useAppDispatch();

  const { value: searchValue } = useSearch();
  useEffect(() => {
    const ffiles = files.filter((file) => file.FileName.includes(searchValue));
    setSearchedFiles(ffiles);
  }, [dispatch, searchValue, files]);

  //   const { result: searchResult, value: searchValue } = useSearch();
  const [showPdfViewerModal, { show: handleShowPDF, hide: handleHidePDF }] =
    useShow();

  const [showBEModal, { show: handleShowBE, hide: handleHideBE }] = useShow();

  const [showDeleteModal, { show: handleShowDelete, hide: handleHideDelete }] =
    useShow();

  const openFile = useCallback(
    async (file: FileType) => {
      if (file.FileType === 0) {
        handleShowPDF();
        dispatch(getFile({ fileId: file.Id }));
      }
    },
    [dispatch, handleShowPDF]
  );

  const deleteFile = useCallback(
    async (file: FileType) => {
      console.log(file);
      if (file.FileType === 0) {
        dispatch(selectFile({ file }));
        handleShowDelete();
      }
    },
    [dispatch, handleShowDelete]
  );

  return (
    <>
      <PDFViewerModal
        show={showPdfViewerModal}
        onClose={() => {
          handleHidePDF();
          dispatch(clearSelectedFile());
        }}
      />

      <WorkflowModal
        show={showWorkflowStepsModal}
        onClose={() => {
          dispatch(handleHideWorkflowStepsModal());
          // dispatch(clearSelectedFile());
        }}
      />

      <SignerManagerModal
        show={showWorkflowSignersModal}
        onClose={() => {
          dispatch(handleHideWorkflowSignersModal());
          // dispatch(clearSelectedFile());
        }}
      />

      <EstimateModal
        show={showEstimateModal}
        onClose={() => {
          dispatch(handleHideEstimateModal());
          // dispatch(clearSelectedFile());
        }}
      />

      <BalanceErrorModal show={showBEModal} onClose={handleHideBE} />

      <FileDeleteModal
        show={showDeleteModal}
        onClose={() => {
          dispatch(clearSelectedFile());
          handleHideDelete();
        }}
        onCallback={() => dispatch(fetchFiles())}
      />
      <>
        <div className={styles.fileList}>
          {!!files?.length && (
            <>
              <div className={classNames(styles.header, "col-name")}>Name</div>
              <div className={classNames(styles.header, "col-size")}>Size</div>
              <div className={classNames(styles.header, "col-action")}></div>
            </>
          )}

          {(filesLoading || profileLoading) && (
            <>
              {Array.from(Array(24).keys()).map((i) => (
                <div
                  key={`file-skeleton-${i}`}
                  className="flex gap-2 py-4 px-2 h-s"
                >
                  {(i + 1) % 3 === 1 && (
                    <>
                      <Skeleton width={30} height={30} />
                      <Skeleton width={200} height={30} />
                    </>
                  )}
                  {(i + 1) % 3 === 2 && (
                    <>
                      <Skeleton width={100} height={30} />
                    </>
                  )}
                  {(i + 1) % 3 === 0 && (
                    <>
                      <Skeleton width={30} height={30} />
                      <Skeleton width={30} height={30} />
                      <Skeleton width={30} height={30} />
                    </>
                  )}
                </div>
              ))}
            </>
          )}
          {!filesLoading &&
            !profileLoading &&
            filteredFiles?.map((file) => (
              <File
                key={`file-${file.Id}`}
                file={file}
                extension={file.FileType}
                name={file.FileName}
                onOpenFile={openFile}
                onDeleteFile={deleteFile}
                onOpenError={handleShowBE}
                size={file.FileSize || "0"}
                createdAt={file.CreatedAt as string}
              />
            ))}
        </div>
        {!files.length && !filesLoading && (
          <div className={styles.empty}>
            <EmptyState />
            <p className="text-2xl text-slate-300 mt-4 font-medium">
              Drop PDF file here
            </p>
            <p className="text-sm text-slate-300">
              Or use “Upload file” button.
            </p>
          </div>
        )}
      </>
    </>
  );
}

export default FileList;
