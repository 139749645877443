import classNames from "classnames";
import { useCallback, useEffect, useState } from "react";
// import toast from "react-hot-toast";
// import { useSearch } from "../providers/searchProvider";
import styles from "./../../styles/WorkflowList.module.scss";
import WorkflowItem from "./workflowListItem";
import Skeleton from "./../skeleton";
import { ReactComponent as EmptyState } from "./../../assets/icons/empty-state.svg";
import WorkflowModal, { WorkflowStatusModal } from "./../modals/workflowModal";
import { SignerManagerModal } from "./../modals/workflowModal";
import EstimateModal, { BalanceErrorModal } from "./../modals/estimateModal";
import WorkflowDeleteModal from "./../modals/workflowDeleteModal";
import { useAppDispatch, useAppSelector } from "../../hooks";
import useShow from "../../hooks/useShow";
import {
  selectShowWorkflowStepsModal,
  handleHideWorkflowStepsModal,
  selectShowWorkflowSignersModal,
  handleHideWorkflowSignersModal,
  handleHideEstimateModal,
  selectShowEstimateModal,
  selectWorkflows,
  selectWorkflowListLoading,
  selectWorkflow,
  getWorkflowsAsync,
  clearSelectedWorkflow,
  selectWorkflowsPagination,
  changePage,
  selectShowWorkflowStatusModal,
  handleHideStatusModal,
} from "../../store/workflowSlice";
import { clearSelectedFile } from "../../store/filesSlice";
import { Workflow } from "../../types/workflow";
import { toast } from "react-hot-toast";
import Pagination from "../pagination";
import useScrollToTop from "../../hooks/useScrollToTop";
import { useSearch } from "../../providers/SearchProvider";

function WorkflowList() {
  const workflows = useAppSelector(selectWorkflows);
  const [filteredWorkflow, setFilteredWorkflow] = useState<Workflow[]>([]);
  const workflowsPagination = useAppSelector(selectWorkflowsPagination);
  const workflowsLoading = useAppSelector(selectWorkflowListLoading);

  const showWorkflowStepsModal = useAppSelector(selectShowWorkflowStepsModal);
  const showWorkflowSignersModal = useAppSelector(
    selectShowWorkflowSignersModal
  );
  const showWorkflowStatusModal = useAppSelector(selectShowWorkflowStatusModal);

  const showEstimateModal = useAppSelector(selectShowEstimateModal);
  const dispatch = useAppDispatch();

  const { value: searchValue } = useSearch();
  useEffect(() => {
    const fworkflow = workflows.filter((workflow) =>
      workflow.FileName?.includes(searchValue)
    );
    setFilteredWorkflow(fworkflow);
  }, [dispatch, searchValue, workflows]);

  const scrollToTop = useScrollToTop({ behavior: "smooth" });
  //   const { result: searchResult, value: searchValue } = useSearch();

  const [
    showBalanceErrorModal,
    { show: handleShowBalanceError, hide: handleHideBalanceError },
  ] = useShow();

  const [showDeleteModal, { show: handleShowDelete, hide: handleHideDelete }] =
    useShow();

  useEffect(() => {
    dispatch(getWorkflowsAsync());
    scrollToTop();
  }, [dispatch, scrollToTop, workflowsPagination.pageNumber]);

  const deleteWorkflow = useCallback(
    async (workflow: Workflow) => {
      console.log(workflow);
      dispatch(selectWorkflow(workflow));
      handleShowDelete();
    },
    [dispatch, handleShowDelete]
  );

  const handleShowWorkflowError = (message: string) => {
    if (message === "balance") {
      handleShowBalanceError();
    } else {
      toast.error(message);
    }
  };
  return (
    <>
      <WorkflowModal
        show={showWorkflowStepsModal}
        onClose={() => {
          dispatch(handleHideWorkflowStepsModal());
        }}
      />

      <SignerManagerModal
        show={showWorkflowSignersModal}
        onClose={() => {
          dispatch(clearSelectedFile());
          dispatch(handleHideWorkflowSignersModal());
        }}
      />

      <EstimateModal
        show={showEstimateModal}
        onClose={() => {
          dispatch(handleHideEstimateModal());
          // dispatch(getWorkflowsAsync());
        }}
      />

      <BalanceErrorModal
        show={showBalanceErrorModal}
        onClose={handleHideBalanceError}
      />

      <WorkflowDeleteModal
        show={showDeleteModal}
        onClose={() => {
          dispatch(clearSelectedWorkflow());
          handleHideDelete();
        }}
        onCallback={() => dispatch(getWorkflowsAsync())}
      />

      <WorkflowStatusModal
        show={showWorkflowStatusModal}
        onClose={() => {
          // dispatch(clearSelectedWorkflow());
          dispatch(handleHideStatusModal());
        }}
      />
      <>
        <div className={styles.workflowList}>
          {!!workflows?.length && (
            <>
              <div className={classNames(styles.header, "col-name")}>Name</div>
              <div className={classNames(styles.header, "col-status")}>
                Status
              </div>
              <div className={classNames(styles.header, "col-Progress")}>
                Progress
              </div>
              <div className={classNames(styles.header, "col-lastModify")}>
                Last Modify
              </div>
              <div className={classNames(styles.header, "col-action")}></div>
            </>
          )}

          {workflowsLoading && (
            <>
              {Array.from(Array(50).keys()).map((i) => (
                <div
                  key={`file-skeleton-${i}`}
                  className={`flex gap-2 py-4 px-2 h-s ${
                    (i + 1) % 5 === 0 ? "justify-end" : ""
                  }`}
                >
                  {(i + 1) % 5 === 1 && (
                    <>
                      <Skeleton width={30} height={30} />
                      <Skeleton width={200} height={30} />
                    </>
                  )}
                  {(i + 1) % 5 === 2 && (
                    <>
                      <Skeleton width={50} height={30} />
                    </>
                  )}
                  {(i + 1) % 5 === 3 && (
                    <>
                      <Skeleton width={250} height={30} />
                    </>
                  )}
                  {(i + 1) % 5 === 4 && (
                    <>
                      <Skeleton width={70} height={30} />
                    </>
                  )}
                  {(i + 1) % 5 === 0 && (
                    <>
                      <Skeleton width={30} height={30} />
                      <Skeleton width={30} height={30} />
                    </>
                  )}
                </div>
              ))}
            </>
          )}
          {!workflowsLoading &&
            filteredWorkflow?.map((workflow) => (
              <WorkflowItem
                key={`file-${workflow.Id}`}
                workflow={workflow}
                // file={file}
                onDeleteWorkflow={deleteWorkflow}
                onError={handleShowWorkflowError}
              />
            ))}
        </div>
        <div className="flex justify-center py-8 mb-12 px-8">
          {workflowsPagination.pageSize > 1 && (
            <Pagination
              totalCount={workflowsPagination.total}
              currentPage={workflowsPagination.pageNumber}
              pageSize={workflowsPagination.pageSize}
              onPageChange={(page) => dispatch(changePage({ page }))}
            />
          )}
        </div>
        {workflows.length === 0 && !workflowsLoading && (
          <div className={styles.empty}>
            <EmptyState />
            <p className="text-2xl text-slate-300 mt-4 font-medium">
              Empty workflow
            </p>
            {/* <p className="text-sm text-slate-300">Use “Upload file” button.</p> */}
          </div>
        )}
      </>
    </>
  );
}

export default WorkflowList;
