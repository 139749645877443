import { PropsWithChildren } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import { dragReorder } from "../utils/helper";

const DragArea = <T extends {}>({
  children,
  items,
  onReorderEnd,
}: PropsWithChildren<{
  items: Array<T>;
  onReorderEnd: (result: any) => void;
}>) => {
  const onDragEnd = (result: any) => {
    if (!result.destination) {
      return;
    }
    const newItems = dragReorder(
      items,
      result.source.index,
      result.destination.index
    );
    onReorderEnd(newItems);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided, snapshot) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            className={`${snapshot.isDraggingOver ? "" : ""}`}
          >
            {children}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

const DragItem = ({
  children,
  id,
  index,
}: PropsWithChildren<{ id: number; index: number }>) => {
  return (
    <Draggable key={id} draggableId={String(id)} index={index}>
      {(provided, snapshot) => {
        // console.log(provided);
        return (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            style={{
              ...provided.draggableProps.style,
              marginBottom: 24,
            }}
          >
            {children}
          </div>
        );
      }}
    </Draggable>
  );
};

export { DragArea, DragItem };
