import { useCallback, useState } from "react";
import Button from "./../../button";
import { useWallet } from "@solana/wallet-adapter-react";
import { useMetamask } from "../../../providers/MetamaskProvider";
import bs58 from "bs58";
import { qrGenerator } from "./maker";

const CreateSignImage = ({
  onChange,
  onSign,
}: {
  onChange?: (base64: string) => void;
  onSign: () => void;
}) => {
  const [signature, setSignature] = useState<string | null>(null);

  const { signMessage } = useWallet();
  //   const { signMessage: metaSignMessage } = useMetamask();

  const handleSignMessage = useCallback(async () => {
    if (typeof signMessage === "function") {
      const message = new TextEncoder().encode(
        "sign this message for creating a signature with your wallet. Nonce:" +
          new Date().toISOString()
      );
      const signature = await signMessage(message);
      const encodedSignature = bs58.encode(signature);
      setSignature(encodedSignature);
      const value = await qrGenerator(encodedSignature);
      if (typeof onChange === "function") onChange(value);
    }
  }, [onChange, signMessage]);

  return (
    <div>
      <Button
        className="mt-8"
        fullWidth
        withLoading
        onClick={handleSignMessage}
      >
        create signature
      </Button>

      <Button
        isDisabled={signature === null}
        className="mt-8"
        fullWidth
        withLoading
        onClick={onSign}
      >
        Sign Document
      </Button>
    </div>
  );
};

export default CreateSignImage;
