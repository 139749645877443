import { useEffect, useRef, useCallback } from "react";
import Button from "../components/button";
import FileList from "../components/fileList";
import { useAppDispatch } from "../hooks";
import { fetchFiles } from "../store/filesSlice";
import { toast } from "react-hot-toast";
import { uploadApi } from "../api/filesApi";
import { useDropzone } from "react-dropzone";
import classNames from "classnames";
import styles from "./../styles/global.module.scss";

export default function HomePage() {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(fetchFiles());
  }, [dispatch]);

  const upload = useCallback(
    async (file: File) => {
      const _t = toast.loading(`Uploading file`);
      try {
        await uploadApi(file);
        toast.success(`upload complete`, {
          id: _t,
        });
        dispatch(fetchFiles());
      } catch (e) {
        toast.error(`Failed!`, {
          id: _t,
        });
      }
      try {
        if (fileInputRef.current) {
          fileInputRef.current.value = "";
        }
      } catch (e) {}
    },
    [dispatch]
  );

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      upload(acceptedFiles[0]);
    },
    [upload]
  );

  const { isDragActive, getRootProps, getInputProps } = useDropzone({
    onDrop,
    noClick: true,
  });

  const handleUploadFiles = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const files = e.target.files;
      if (files) {
        upload(files[0]);
      }
    },
    [upload]
  );

  const handleUploadClick = useCallback(() => {
    if (fileInputRef.current) fileInputRef.current.click();
  }, []);

  return (
    <>
      <input
        type="file"
        accept="application/pdf"
        hidden={true}
        ref={fileInputRef}
        multiple={true}
        onChange={handleUploadFiles}
      />

      <div className="flex sticky py-6 px-8 top-0 bg-white transition-all duration-500">
        <div className="text-slate-800 text-xl font-medium">My files</div>
        <div className="btns ml-auto flex gap-2">
          <Button onClick={handleUploadClick}>Upload File</Button>
        </div>
      </div>
      <div
        {...getRootProps({
          className: classNames(styles.homeContent, {
            [styles.dragActive]: isDragActive,
          }),
        })}
      >
        <input {...getInputProps()} />
        <FileList />
      </div>
    </>
  );
}
