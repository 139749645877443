import { useCallback, useRef, useState } from "react";
import { toast } from "react-hot-toast";
import { toBase64 } from "../../../utils/file";
import Button from "./../../button";

const UploadSignImage = ({
  onChange,
  onSign,
}: {
  onChange?: (base64: string) => void;
  onSign: () => void;
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [file, setFile] = useState<File | null>(null);
  const [image, setImage] = useState<string | null>(null);

  const upload = useCallback(async (file: File) => {
    const _t = toast.loading(`Uploading file`);
    try {
      // await uploadApi(file);
      toast.success(`upload complete`, {
        id: _t,
      });
      //
    } catch (e) {
      toast.error(`Failed!`, {
        id: _t,
      });
    }
    try {
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
    } catch (e) {}
  }, []);

  const handleChangeFile = useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      const files = e.target.files;
      if (files) {
        const image = await toBase64(files[0]);
        setFile(files[0]);
        setImage(image);
        if (typeof onChange === "function") onChange(image);
      }
    },
    [onChange]
  );

  const handleUploadClick = useCallback(() => {
    if (fileInputRef.current) fileInputRef.current.click();
  }, []);

  return (
    <div>
      <input
        type="file"
        accept="images/*"
        hidden={true}
        ref={fileInputRef}
        multiple={true}
        onChange={handleChangeFile}
      />

      <div
        className="w-full bg-sky-50 text-sky-500 rounded-lg p-3 mt-8 cursor-pointer h-40 flex justify-center items-center"
        onClick={handleUploadClick}
      >
        {file === null && "Click To Upload Sign Image"}
        {file && <img className="h-full" src={image || ""} alt="" />}
      </div>
      {file && (
        <span className="px-1 text-xs text-slate-500">
          Click the blue area to change
        </span>
      )}

      <Button
        isDisabled={file === null}
        className="mt-5"
        fullWidth
        withLoading
        onClick={onSign}
      >
        Sign Document
      </Button>
    </div>
  );
};
export default UploadSignImage;
