import Modal, { ModalContainerProps } from "./modal";
import styles from "./../../styles/TopUpModal.module.scss";
import Button from "../button";
import {
  selectSelectedWorkflow,
  deleteWorkflowsAsync,
  getWorkflowsAsync,
} from "../../store/workflowSlice";
import { useAppDispatch, useAppSelector } from "../../hooks";

function WorkflowDeleteModal({
  show,
  onClose,
  onCallback,
}: ModalContainerProps & { onCallback?: () => void }) {
  const workflow = useAppSelector(selectSelectedWorkflow);
  const dispatch = useAppDispatch();

  return (
    <Modal
      title={"File Contract"}
      bodyClassName={styles.body}
      show={show}
      onClose={onClose}
    >
      <div className="flex justify-center py-4 text-primary-500 flex-col gap-4">
        <div className="flex w-full">Are you sure to delete this workflow?</div>
        <div className="flex w-full justify-end">
          {workflow && (
            <Button
              withLoading
              color="danger"
              onClick={() =>
                dispatch(
                  deleteWorkflowsAsync({ workflowId: workflow.Id })
                ).then(() => {
                  dispatch(getWorkflowsAsync());
                  onClose();
                  onCallback && onCallback();
                })
              }
            >
              Yes
            </Button>
          )}
        </div>
      </div>
    </Modal>
  );
}

export default WorkflowDeleteModal;
