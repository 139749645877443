import React from "react";
import styles from "./../styles/Skeleton.module.scss";

export type SkeletonProps = {
  width: number;
  height: number;
};

function Skeleton({ width, height }: SkeletonProps) {
  return <div className={styles.skeleton} style={{ width, height }} />;
}

export default Skeleton;
