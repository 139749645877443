import { useMemo } from "react";
import { ReactComponent as DocumentIcon } from "./../assets/icons/document-text.svg";
import { ReactComponent as DefaultIcon } from "./../assets/icons/document.svg";
import { ReactComponent as PDFIcon } from "./../assets/icons/pdf.svg";

export type FileTypeType = 0 | 1;
export type FileIconProps = {
  type: FileTypeType;
};
function FileIcon({ type }: FileIconProps) {
  const renderIcon = useMemo(() => {
    switch (type) {
      case 0:
        return <PDFIcon />;
      case 1:
        return <DocumentIcon />;
      default:
        return <DefaultIcon />;
    }
  }, [type]);
  return renderIcon;
}

export default FileIcon;
