import { CSSProperties, useState } from "react";
import { Document } from "react-pdf/dist/esm/entry.webpack5";
// import { ReactComponent as LeftArrowIcon } from "../../assets/icons/left-arrow.svg";
import PdfPage from "./pdfPage";
import {
  PDFDocumentProxy,
  PDFPageProxy,
} from "pdfjs-dist/types/src/display/api";
// import { toast } from "react-hot-toast";
import { getStorage } from "../../utils/helper";
import { PdfReaderType } from "../../types/pdf";
import classNames from "classnames";
type PDFPageProxyType = { width: number | undefined } & PDFPageProxy;

const PdfReader = ({
  path,
  width,
  height,
  documentClassName,
  pageClassName,
  onLoadSuccess,
  onPageLoadSuccess,
  onLoadError,
}: PdfReaderType) => {
  const [show, setShow] = useState(false);
  const [numPages, setNumPages] = useState<number | null>(null);

  async function onDocumentLoadSuccess(pdf: PDFDocumentProxy) {
    setNumPages(pdf.numPages);
    setTimeout(() => setShow(true), 200);
    if (onLoadSuccess) setTimeout(() => onLoadSuccess(), 1500);
  }

  async function _onPageLoadSuccess(page: PDFPageProxyType) {
    // toast.success("_onPageLoadSuccess");

    if (onPageLoadSuccess && page.width) {
      let width = page.width;
      const ww = window.innerWidth;
      if (page.width > ww) {
        width = ww - 60;
      } else if (ww <= 425) {
        width = ww - 100;
      } else if (ww > 425 && ww <= 768) {
        width = ww - 100;
      } else {
        width = ww / 2.5;
      }

      onPageLoadSuccess(width);
    }
  }

  return (
    <div
      className={`${
        show ? "opacity-100" : "opacity-0"
      } transition-all duration-1000`}
    >
      <Document
        className={classNames("transition-all duration-1000", [
          documentClassName,
        ])}
        file={path}
        onSourceError={() => console.log("err")}
        onLoadSuccess={(pdf: any) => onDocumentLoadSuccess(pdf)}
        onLoadError={(e: Error) => onLoadError && onLoadError(e)}
        options={{
          httpHeaders: {
            Authorization: `Bearer ${getStorage("token")}`,
          },
        }}
      >
        {Array.from(new Array(numPages), (el, index) => (
          <div className={pageClassName} key={index}>
            <PdfPage
              pageNumber={index + 1}
              height={height}
              width={width}
              onRenderSuccess={(page: any) => _onPageLoadSuccess(page)}
              // onLoadError={(e) => toast.error("page error: " + e.message)}
              // onRenderError={(e) => toast.error("page error: " + e.message)}
              // onLoadSuccess={(pdf: any) => toast.success("page: onLoadSuccess")}
            />
          </div>
        ))}
      </Document>
      {/* <div className="flex justify-between items-center w-full h-[34px]">
        <p className="pagination-title text-sm text-slate-600">
          page {pageNumber} of {numPages}
        </p>
        <div className="flex gap-3 items-center">
          <button
            className="bg-sky-300 rounded-xl disabled:bg-sky-100"
            onClick={goToPrevPage}
            disabled={pageNumber <= 1}
          >
            <LeftArrowIcon className="rotate-180 w-8 h-8 text-white p-1" />
          </button>
          <button
            className="bg-sky-300 rounded-xl disabled:bg-sky-100"
            onClick={goToNextPage}
            disabled={numPages ? pageNumber >= numPages : true}
          >
            <LeftArrowIcon className="w-8 h-8 text-white p-1" />
          </button>
        </div>
      </div> */}
    </div>
  );
};

export default PdfReader;
