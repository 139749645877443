import { PageProps } from "react-pdf";
import { Page } from "react-pdf/dist/esm/entry.webpack5";

interface MyPageProps extends PageProps {
  children?: React.ReactNode;
}

export default function PdfPage(props: MyPageProps) {
  const { children, className, ...rest } = props;
  return <Page {...rest}>{children}</Page>;
}
