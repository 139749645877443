import Modal, { ModalContainerProps } from "./modal";
import { useAppDispatch, useAppSelector } from "../../hooks";
import WorkflowManeger from "../../components/workflow/workflowManeger";
import SignerManeger from "../../components/workflow/signerManeger";
import Button from "../button";
import ContactsModal from "./contactsModal";
import {
  selectShowContactsModal,
  handleShowContactsModal,
  handleHideContactsModal,
} from "../../store/contactsSlice";
import { clearSelectedWorkflow } from "../../store/workflowSlice";
import WorkflowStatus from "../workflow/workflowStatus";

export type FileViewerProps = ModalContainerProps;

function WorkflowModal({ show, onClose }: FileViewerProps) {
  const showContactsModal = useAppSelector(selectShowContactsModal);
  const dispatch = useAppDispatch();

  const ContactsBtn = () => {
    return (
      <Button
        className="text-gray-600 text-sm"
        extraLight={true}
        onClick={() => dispatch(handleShowContactsModal())}
      >
        My Contacts
      </Button>
    );
  };
  const handleClose = () => {
    dispatch(clearSelectedWorkflow());
    onClose();
  };
  return (
    <Modal
      title={"Add recipients"}
      modalClassName="w-full lg:w-[850px] bg-slate-100"
      containerStyle={{ alignItems: "flex-start", overflowY: "auto" }}
      show={show}
      onClose={handleClose}
      Header={ContactsBtn}
    >
      <ContactsModal
        show={showContactsModal}
        onClose={() => dispatch(handleHideContactsModal())}
      />
      <WorkflowManeger />
    </Modal>
  );
}

function SignerManagerModal({ show, onClose }: ModalContainerProps) {
  return (
    <Modal
      title={"Choose positions"}
      modalClassName="w-full lg:w-[600px] bg-slate-100"
      containerStyle={{ alignItems: "flex-start", overflowY: "auto" }}
      show={show}
      onClose={onClose}
    >
      <SignerManeger />
    </Modal>
  );
}

function WorkflowStatusModal({ show, onClose }: ModalContainerProps) {
  return (
    <Modal
      title={"Show contract workflow"}
      modalClassName="w-full lg:w-[600px] bg-slate-100"
      containerStyle={{ alignItems: "flex-start", overflowY: "auto" }}
      show={show}
      onClose={onClose}
    >
      <WorkflowStatus />
    </Modal>
  );
}
export { SignerManagerModal, WorkflowStatusModal };
export default WorkflowModal;
