import { useState } from "react";
import { ReactComponent as SignIcon } from "../../../assets/icons/edit.svg";
import { ReactComponent as DeleteIcon } from "../../../assets/icons/close.svg";
const SignPlaceAnnotation = ({
  id,
  left,
  bottom,
  onDelete,
}: {
  id: number;
  left: number;
  bottom: number;
  onDelete?: (id: number) => void;
}) => {
  const [isHover, setIsHover] = useState(false);

  return (
    <div
      className="sign-place-annotation flex p-2 rounded-md items-center justify-center bg-sky-500 text-white shadow relative"
      style={{
        position: "absolute",
        left: left + "%",
        bottom: bottom + "%",
      }}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      {isHover && typeof onDelete === "function" && (
        <span
          onClick={() =>
            typeof onDelete === "function"
              ? onDelete(id)
              : console.log("delete")
          }
          className="w-6 h-6 bg-red-500 text-white flex justify-center items-center absolute -right-3 -top-3 rounded cursor-pointer transition-all duration-500"
        >
          <DeleteIcon className="w-4 h-4" />
        </span>
      )}

      <SignIcon className="w-5 h-5" />
    </div>
  );
};
export default SignPlaceAnnotation;
