import { AuthFetch, FetchCatchResponse, FetchResponse } from "./index";
import {
  ConfirmApiResponce,
  StatusApiResponce,
  CreateApiResponce,
} from "../types/payment";
import axios from "axios";
import { PaginationApiType } from "../types";

export async function createPayment(
  value: number
): Promise<FetchResponse<CreateApiResponce>> {
  try {
    const { data } = await AuthFetch(false).post(`/payment`, {
      Amount: value,
    });
    return {
      data: data.Result,
      status: true,
      message: data.Message,
    };
  } catch (e) {
    return FetchCatchResponse(e);
  }
}

export async function confirmTopUpPayment(
  signature: string,
  amount: number
): Promise<FetchResponse<ConfirmApiResponce>> {
  try {
    const { data } = await AuthFetch(false).post(
      `/payment/confirm`,
      {
        Signature: signature,
        Amount: amount,
      },
      {
        headers: {
          Accept: "*/*",
          "Content-Type": "application/json",
          Connection: "keep-alive",
        },
      }
    );
    return {
      data: data.Result,
      status: true,
      message: data.Message,
    };
  } catch (e) {
    return FetchCatchResponse(e);
  }
}

export async function checkPaymentStatus(
  signature: string
): Promise<FetchResponse<StatusApiResponce>> {
  try {
    const { data } = await AuthFetch(false).get(
      `/payment/status?signature=${signature}`
    );
    return {
      data: data.Result,
      status: true,
      message: data.Message,
    };
  } catch (e) {
    return FetchCatchResponse(e);
  }
}

export async function getDjibConfigApi(): Promise<FetchResponse<any>> {
  try {
    const { data } = await axios.get(`https://pricing.djib.io/api/v1/config`);
    return data.data;
    // return {
    //   data: data.Result,
    //   status: true,
    //   message: data.Message,
    // };
  } catch (e) {
    return FetchCatchResponse(e);
  }
}

export async function getPayments(
  pageNumber: number = 1,
  pageSize: number = 0
): Promise<
  FetchResponse<{
    activities: Array<ConfirmApiResponce>;
    pagination: PaginationApiType;
  }>
> {
  try {
    const { data } = await AuthFetch(false).get(
      `/user/activity?pageNumber=${pageNumber}&pageSize=${pageSize}`
    );
    return {
      data: { activities: data.Result, pagination: data.Pagination },
      status: true,
      message: data.Message,
    };
  } catch (e) {
    return FetchCatchResponse(e);
  }
}
