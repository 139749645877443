import { useEffect, useState } from "react";
import Modal, { ModalContainerProps } from "./modal";
import styles from "./../../styles/TopUpModal.module.scss";
import { ReactComponent as Logo } from "./../../assets/icons/logo.svg";
import Input from "../input";
import Button from "../button";
import classNames from "classnames";
import { selectCredit, fetchBalance } from "../../store/profileSlice";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { estimateApi, submitWorkflowApi } from "../../api/workflowApi";
import {
  getWorkflowsAsync,
  handleHideEstimateModal,
  selectSelectedWorkflow,
} from "../../store/workflowSlice";
import { Link } from "react-router-dom";
import { toast } from "react-hot-toast";

function EstimateModal({ show, onClose }: ModalContainerProps) {
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState("");
  const [canSubmit, setCanSubmit] = useState(false);
  const credit = useAppSelector(selectCredit);
  const workflow = useAppSelector(selectSelectedWorkflow);
  const [value, setValue] = useState<number>(0);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (credit === undefined) {
      setLoading(true);
      dispatch(fetchBalance()).then(() => {
        setLoading(false);
      });
    }
  }, [credit, dispatch]);

  useEffect(() => {
    async function getCallEstimate(workflowId: number) {
      setLoading(true);
      setValue(0);
      const res = await estimateApi({ workflowId });
      if (res.status) {
        setMessage("");
        setValue(res.data);
        if (credit && credit >= res.data) {
          setCanSubmit(true);
        } else {
          setCanSubmit(false);
        }
      } else {
        setMessage(res.message);
        setCanSubmit(false);
      }
      setLoading(false);
    }

    if (show) {
      if (workflow !== null) getCallEstimate(workflow.Id);
      else {
        dispatch(handleHideEstimateModal());
      }
    }
  }, [credit, dispatch, show, workflow]);

  const handleSubmitWorkflow = async () => {
    if (workflow) {
      const res = await submitWorkflowApi({ workflowId: workflow.Id });
      if (res.status) {
        toast.success(res.message);
        dispatch(getWorkflowsAsync());
        onClose();
      } else {
        toast.error(res.message);
      }
    }
  };
  return (
    <Modal
      title={"Estimate"}
      bodyClassName={styles.body}
      show={show}
      onClose={onClose}
    >
      <div className="flex justify-center py-4 text-primary-500">
        <Logo width={100} height={100} />
      </div>
      <Input
        readOnly
        contentEditable={false}
        containerClassName="mt-2"
        type={"djib"}
        onChange={(e) => {
          if (e.target.value === "-") {
            setValue(0);
          } else if (Number(e.target.value) < 0) {
            setValue(0);
          } else setValue(Number(e.target.value));
        }}
        value={value}
      />
      {message && (
        <div className={classNames(styles.newCredit, "mt-2")}>{message}</div>
      )}

      {credit !== undefined && value > credit && (
        <div className={classNames(styles.newCredit, "mt-2")}>
          There is not enough<span>DJIB</span>in your Balance.
          <br /> top up your account{" "}
          <Link className="text-sky-500" to="/settings">
            here
          </Link>{" "}
          and resume later
        </div>
      )}
      <Button
        className="mt-4"
        fullWidth={true}
        withLoading={true}
        isDisabled={loading || !canSubmit}
        onClick={handleSubmitWorkflow}
      >
        Pay and Submit
      </Button>
    </Modal>
  );
}

function BalanceErrorModal({ show, onClose }: ModalContainerProps) {
  const [loading, setLoading] = useState(true);
  const credit = useAppSelector(selectCredit);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (credit === undefined) {
      setLoading(true);
      dispatch(fetchBalance()).then(() => {
        setLoading(false);
      });
    }
  }, [credit, dispatch]);

  return (
    <Modal title={""} bodyClassName={styles.body} show={show} onClose={onClose}>
      <div className="flex justify-center py-4 text-primary-500">
        <Logo width={100} height={100} />
      </div>
      {!loading && (
        <Input
          readOnly
          contentEditable={false}
          containerClassName="mt-2"
          type={"djib"}
          value={credit || 0}
        />
      )}

      {credit !== undefined && credit === 0 && (
        <div className={classNames(styles.newCredit, "mt-2")}>
          There is not enough<span>DJIB</span>in your Balance.
          <br /> top up your account{" "}
          <Link className="text-sky-500" to="/settings">
            here
          </Link>{" "}
          and resume later
        </div>
      )}
    </Modal>
  );
}
export { BalanceErrorModal };
export default EstimateModal;
