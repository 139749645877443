import WorkflowList from "./../components/workflow/workflowList";

export default function MyContaractsPage() {
  return (
    <>
      <div className="flex sticky py-6 px-8 top-0 bg-white transition-all duration-500 z-20">
        <div className="text-slate-800 text-xl font-medium">My Contracts</div>
        <div className="btns ml-auto flex gap-2"></div>
      </div>
      <WorkflowList />
    </>
  );
}
