import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import PdfReader from "../components/pdf/pdfReader";
import { getPDF } from "../components/pdf/tools/maker";
import Loading from "../components/loading";
import { ReactComponent as LogoText } from "./../assets/icons/logo-text.svg";
import { ReactComponent as NotFindIcon } from "./../assets/icons/404.svg";
import { ReactComponent as SwissIcon } from "./../assets/icons/swiss.svg";
import { ApiBaseURL } from "../api";
import Button from "../components/button";
import { toast } from "react-hot-toast";
import { saveAs } from "file-saver";

export default function ShowFilePage() {
  const [loading, setLoading] = useState(true);
  const [status, setStatus] = useState<{
    type: "error" | "success";
    message?: string;
  } | null>(null);

  const { token } = useParams();
  const [fileUrl, setFileUrl] = useState<string | null>(null);

  useEffect(() => {
    if (token) {
      setFileUrl(`${ApiBaseURL}/public/download-output/${token}`);
    }
  }, [token]);

  const handleDownloadDocument = async () => {
    if (fileUrl) {
      const fileData = await getPDF(fileUrl);
      if (fileData && fileData.status) {
        const blob = fileData.blob;
        blob && saveAs(blob, fileData.filename);
      } else {
        toast.error("There is no WorkFlowOutPut file");
      }
    }
  };

  // if (token === undefined) window.location.href = "/";
  return (
    <>
      {loading && (
        <div className="fixed top-0 left-0 bottom-0 right-0 w-screen h-screen bg-white z-10">
          <Loading style={{ height: "100%" }} />
        </div>
      )}
      <div className="flex flex-col justify-center items-center  md:gap-10">
        <div className="w-full sticky top-0 z-10 bg-white border-b-2 border-gray-200 flex items-center h-20 px-7 md:px-8 lg:px-14 justify-between">
          <Link to={"/"} className="flex items-center gap-1">
            <LogoText className="w-[90px]" />
            <span className="Nothing-font font-semibold text-lg">sign</span>
          </Link>

          <Button
            light={!loading}
            extraLight={loading}
            isDisabled={loading}
            onClick={handleDownloadDocument}
            withLoading
          >
            Download
          </Button>
        </div>
        <div className="relative min-h-[85vh] w-full flex flex-col justify-start items-center">
          {status !== null && (
            <div className="w-full h-full flex flex-col justify-start items-center bg-white mt-10">
              <NotFindIcon className="max-w-sm min-w-md" />
              <span className="text-slate-500 font-normal text-xl mt-4">
                {status.message}
              </span>
            </div>
          )}
          {fileUrl && status === null && (
            <div className="w-full md:w-[700px] overflow-y-auto py-7 mb-24 bg-sky-100 md:rounded-md">
              <PdfReader
                path={fileUrl}
                documentClassName=" flex flex-col items-center gap-7"
                pageClassName="shadow-xl"
                width={window.innerWidth > 600 ? 600 : 300}
                onLoadSuccess={() => setLoading(false)}
                onLoadError={() => {
                  setStatus({
                    type: "error",
                    message: "",
                  });
                  setLoading(false);
                }}
              />
            </div>
          )}
          <div className="w-full bg-white flex items-center h-20 px-7 md:px-8 lg:px-14 absolute bottom-0 z-20">
            <div className="text-base text-center w-full text-slate-400 flex gap-1 items-center justify-center">
              All rights Reserved. <SwissIcon className="w-3 h-3" />
              Swiss Made
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
