import axios, { AxiosError } from "axios";
import { getStorage } from "../utils/helper";
const BackUrl = "https://signapi.djib.io";
export const ApiAuthBaseURL = (isPrivate: boolean = true): string =>
  `${BackUrl}${isPrivate ? "/sign/api/v1" : "/api/v1"}`;
export const ApiBaseURL: string = BackUrl + "/api/v1";

export const Fetch = axios.create({
  baseURL: ApiBaseURL,
});

export const AuthFetch = (isPrivate: boolean = true) =>
  axios.create({
    baseURL: ApiAuthBaseURL(isPrivate),
    headers: {
      Authorization: "Bearer " + getStorage("token"),
    },
  });

export interface FetchResponse<T = any> {
  data: T;
  status: boolean;
  message: string;
  errors?: [];
}

export const FetchCatchResponse = (e: any): FetchResponse => {
  let message = "network error";
  let errors = [];
  if (e instanceof AxiosError) {
    console.log("ERROR", e);
    message = e.response?.data.message || e.response?.data.title;
    errors = e.response?.data.errors;
  } else {
    console.log("ERROR", e);
  }
  return {
    data: null,
    status: false,
    message,
    errors,
  };
};
