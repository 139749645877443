import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ContactsCreateDataType, ContactsStateType } from "../types/contacts";
import {
  getUserContactsApi,
  createContactsApi,
  deleteContactsApi,
} from "../api/contactsApi";
import { RootState } from ".";
import { toast } from "react-hot-toast";

const initialState: ContactsStateType = {
  contacts: [],
  selectedContact: null,
  showContactsModal: false,
  loading: false,
};

export const getContactsAsync = createAsyncThunk(
  "contacts",
  async (_, thunkAPI) => {
    const { data, message, status } = await getUserContactsApi();
    if (!status) {
      return thunkAPI.rejectWithValue({ message });
    }
    return data;
  }
);

export const createContactAsync = createAsyncThunk(
  "contacts/create",
  async (formData: ContactsCreateDataType, thunkAPI) => {
    const { data } = await createContactsApi(formData);
    return data;
  }
);

export const deleteContactAsync = createAsyncThunk(
  "contacts/delete",
  async ({ id }: { id: number }, thunkAPI) => {
    await deleteContactsApi(id);
    return id;
  }
);

const contactsSlice = createSlice({
  name: "contacts",
  initialState,
  reducers: {
    handleHideContactsModal: (state) => {
      state.showContactsModal = false;
    },
    handleShowContactsModal: (state) => {
      state.showContactsModal = true;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getContactsAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(getContactsAsync.fulfilled, (state, action) => {
        state.contacts = action.payload;
        state.loading = false;
      })
      .addCase(getContactsAsync.rejected, (state, action) => {
        // const message = action.payload.message as { message: string };
        toast.error("network error");
        state.loading = false;
      });

    builder
      .addCase(createContactAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(createContactAsync.fulfilled, (state, action) => {
        state.contacts = [...state.contacts, action.payload];
        state.loading = false;
      })
      .addCase(createContactAsync.rejected, (state, action) => {
        toast.error("network error");
        state.loading = false;
      });

    builder
      .addCase(deleteContactAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteContactAsync.fulfilled, (state, action) => {
        state.contacts = [
          ...state.contacts.filter((contact) => contact.Id !== action.payload),
        ];
        state.loading = false;
      })
      .addCase(deleteContactAsync.rejected, (state, action) => {
        toast.error("network error");
        state.loading = false;
      });
  },
});

export const selectContacts = (state: RootState) => state.contacts.contacts;
export const selectSelectedContact = (state: RootState) =>
  state.contacts.selectedContact;
export const selectContactsLoading = (state: RootState) =>
  state.contacts.loading;
export const selectShowContactsModal = (state: RootState) =>
  state.contacts.showContactsModal;

export const { handleShowContactsModal, handleHideContactsModal } =
  contactsSlice.actions;
export default contactsSlice.reducer;
