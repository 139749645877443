import { useEffect, useMemo, useRef } from "react";
import styles from "./../../styles/global.module.scss";
import classNames from "classnames";

import { useAppDispatch, useAppSelector } from "../../hooks";
import { selectSelectedWorkflow } from "../../store/workflowSlice";
import {
  getContactsAsync,
  selectContacts,
  selectContactsLoading,
} from "../../store/contactsSlice";
import { ReactComponent as SignIcon } from "../../assets/icons/edit.svg";
import { ReactComponent as CopyIcon } from "../../assets/icons/copy.svg";

function WorkflowStatus() {
  const ref = useRef(null);
  const workflow = useAppSelector(selectSelectedWorkflow);
  const myContacts = useAppSelector(selectContacts);
  const contactsLoading = useAppSelector(selectContactsLoading);

  const dispatch = useAppDispatch();
  useEffect(() => {
    console.log("myContacts:", myContacts.length);
    if (myContacts.length === 0) {
      dispatch(getContactsAsync());
    }
  }, [dispatch, myContacts.length]);

  if (workflow === null) return <></>;
  return (
    <div
      className={classNames(styles.workflow)}
      ref={ref}
      // onClick={handleClick}
    >
      <div className="w-full pl-6 pr-3 mt-10 pb-0">
        <ol className="relative text-gray-500 border-l border-gray-200 dark:border-gray-700 dark:text-gray-400 w-full">
          {workflow.WorkFlowSteps.map((step, index) => {
            const contract = myContacts.find(
              (contract) => contract.Id === step.ContactId
            );
            return (
              <li key={index} className="mb-10 ml-6">
                <span
                  className={classNames(
                    "absolute flex items-center justify-center w-8 h-8 bg-gray-100 rounded-full -left-4 ring-4 ring-white ",
                    {
                      "bg-gray-100": step.ActionStatus === 1,
                      "bg-green-200": step.ActionStatus === 2,
                    }
                  )}
                >
                  {step.ActionType === 0 && (
                    <SignIcon
                      className={classNames("w-4 h-4", {
                        "text-gray-500": step.ActionStatus !== 2,
                        "text-green-500": step.ActionStatus === 2,
                      })}
                    />
                  )}
                  {step.ActionType === 1 && (
                    <CopyIcon
                      className={classNames("w-4 h-4", {
                        "text-gray-500": step.ActionStatus !== 2,
                        "text-green-500": step.ActionStatus === 2,
                      })}
                    />
                  )}
                </span>
                <div className="flex items-center justify-between">
                  <h3
                    className={classNames("font-medium leading-tight", {
                      "text-slate-700": step.ActionType === 0,
                      "text-slate-500": step.ActionType === 1,
                    })}
                  >
                    {contract?.ContactDetail}
                  </h3>
                  <span className="text-slate-500 text-sm font-light">
                    {step.ActionStatus === 0 && "Pending"}
                    {step.ActionStatus === 1 && "Waiting For Signature"}
                    {step.ActionStatus === 2 && "Done"}
                  </span>
                </div>
                <p className="text-sm text-slate-400">
                  {step.ActionType === 0 ? "Signer" : "Reciepent"}
                </p>
              </li>
            );
          })}
        </ol>
      </div>
    </div>
  );
}

export default WorkflowStatus;
